import React from 'react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Box } from 'src/components-v2/Layout';
import useVisibilityScale from 'src/hooks/useVisibilityScale';
import { Image } from 'src/components-v2/Media';
import useIsPotato from 'src/hooks/useIsPotato';
import useIsChromium from 'src/hooks/useIsChromium';
import { GlobalStyles } from '@mui/material';

// The offset in pixels for how many pixels are needed to
// be scrolled before showing the next card
const SCROLL_OFFSET_DEFAULT = 0;

const PERCENT_SCROLLED_KEY_PREFIX = '--solution-card-percent-scrolled';
interface GradientProps {
  degree: number;
  colorA: string;
  colorAPercent: number;
  colorB: string;
  colorBPercent: number;
  colorC?: string;
  colorCPercent?: number;
}

export interface StickyCardChildrenProps {
  percentElementInView: number;
  getScaledValue: (
    start: number,
    end: number,
    triggerBreakpoint?: null | number,
    shouldReverse?: boolean,
    reversedStartValue?: number,
    reverseOffset?: number,
  ) => number;
  canReverse: boolean;
  pixelsScrolledPastInitialTop: null | number;
  index: number;
  isLastCard: boolean;
}

interface Props {
  children: React.ReactNode | ((props: any) => React.ReactNode);
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  minScale?: number;
  isLastCard?: boolean;
  index?: number;
  fromGradient?: GradientProps;
  toGradient?: GradientProps;
  percentElementInViewRef?: React.MutableRefObject<number>;
}

const gradientPropsToCss = (gradientProps: GradientProps) => {
  return `linear-gradient(${gradientProps.degree}deg, ${gradientProps.colorA} ${gradientProps.colorAPercent}%, ${gradientProps.colorB} ${gradientProps.colorBPercent}%)`;
};

// const getPercentBasedCalculation = (
//   start,
//   end,
//   percentScrolledKey,
//   type = '',
//   percentScrolledIntoView?: number,
// ) => {
//   const percentScrolled =
//     percentScrolledIntoView ?? `var(${percentScrolledKey})`;
//   return `calc(${end - start}${type} * ${percentScrolled} + ${start}${type})`;
// };

// const getColorMix = (
//   colorA: string,
//   colorB: string,
//   percentScrolledKey?: string,
//   percentScrolledIntoView?: number,
// ) => {
//   if (percentScrolledKey == null && percentScrolledIntoView == null) {
//     return colorA;
//   }

//   const percentScrolled =
//     percentScrolledIntoView ?? `var(${percentScrolledKey})`;
//   const colorMixFromPercent = `calc(100% - 100% * ${percentScrolled})`;
//   const colorMixToPercent = `calc(100% * ${percentScrolled})`;
//   return `color-mix(in srgb, ${colorA} ${colorMixFromPercent}, ${colorB} ${colorMixToPercent})`;
// };

// const getGradient = (
//   fromGradient: GradientProps,
//   toGradient: GradientProps,
//   index: number,
//   percentElementInView?: number,
// ) => {
//   const percentScrolledKey = `${PERCENT_SCROLLED_KEY_PREFIX}-${index}`;
//   const degree = getPercentBasedCalculation(
//     fromGradient.degree,
//     toGradient.degree,
//     percentScrolledKey,
//     'deg',
//     percentElementInView,
//   );
//   let linearGradient = 'linear-gradient(' + degree + ', ';

//   // Get first color
//   if (fromGradient.colorA !== toGradient.colorA) {
//     linearGradient += getColorMix(
//       fromGradient.colorA,
//       toGradient.colorA,
//       percentScrolledKey,
//       percentElementInView,
//     );
//   } else {
//     linearGradient += fromGradient.colorA;
//   }
//   linearGradient += ' ';

//   // Get first color percent
//   if (fromGradient.colorAPercent !== toGradient.colorAPercent) {
//     linearGradient += getPercentBasedCalculation(
//       fromGradient.colorAPercent,
//       toGradient.colorAPercent,
//       percentScrolledKey,
//       '%',
//       percentElementInView,
//     );
//   } else {
//     linearGradient += fromGradient.colorAPercent + '%';
//   }
//   linearGradient += ', ';

//   // Get second color
//   if (fromGradient.colorB !== toGradient.colorB) {
//     linearGradient += getColorMix(
//       fromGradient.colorB,
//       toGradient.colorB,
//       percentScrolledKey,
//       percentElementInView,
//     );
//   } else {
//     linearGradient += fromGradient.colorB;
//   }
//   linearGradient += ' ';

//   // Get second color percent
//   if (fromGradient.colorBPercent !== toGradient.colorBPercent) {
//     linearGradient += getPercentBasedCalculation(
//       fromGradient.colorBPercent,
//       toGradient.colorBPercent,
//       percentScrolledKey,
//       '%',
//       percentElementInView,
//     );
//   } else {
//     linearGradient += fromGradient.colorBPercent + '%';
//   }

//   return linearGradient + ')';
// };

const StickyCard: React.FC<Props> = ({
  children,
  sx,
  containerSx,
  minScale,
  isLastCard,
  index,
  fromGradient,
  toGradient,
  percentElementInViewRef,
}) => {
  const isChromium = useIsChromium();
  const isPotato = useIsPotato();
  const useChromiumStyles = isChromium && !isPotato;
  const SCROLL_OFFSET = isPotato ? -50 : SCROLL_OFFSET_DEFAULT;
  const visibilityScale = useVisibilityScale(SCROLL_OFFSET, minScale);
  const {
    ref,
    percentElementInView,
    canReverse,
    pixelsScrolledPastInitialTop,
  } = visibilityScale;
  const getScaledValue = visibilityScale.getScaledValue;
  let animationStyles = {};
  let glowAnimationStyles = {};
  let gradientAnimationStyles = {};

  if (percentElementInViewRef) {
    percentElementInViewRef.current = percentElementInView;
  }

  let cardScaleValue =
    percentElementInView > 22 ? getScaledValue(0.85, 1, null, false) : 0.85;

  let imageScaleValue =
    percentElementInView > 22 ? getScaledValue(0.88, 1, null, false) : 0.88;

  if (index !== 0 || isPotato) {
    cardScaleValue = 1;
    imageScaleValue = 1;
  }

  const expandKey = `--solution-card-${index}-expand`;
  const expandGlowKey = `--solution-card-${index}-expand-glow`;
  const gradientKey = `--solution-card-${index}-gradient`;

  const percentScrolledKey = `${PERCENT_SCROLLED_KEY_PREFIX}-${index}`;
  const startScale = index === 0 && !isPotato ? 0.85 : 1;
  if (useChromiumStyles) {
    animationStyles = {
      background:
        fromGradient != null ? gradientPropsToCss(fromGradient) : undefined,
      [`@keyframes ${expandKey}`]: {
        '0%': {
          borderTopLeftRadius: '40px',
          borderTopRightRadius: '40px',
          transform: `scale(${startScale})`,
          [percentScrolledKey]: 0,
        },
        '22%': {
          transform: `scale(${startScale})`,
        },
        '90%': {
          borderTopLeftRadius: '40px',
          borderTopRightRadius: '40px',
        },
        '100%': {
          transform: 'scale(1)',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          [percentScrolledKey]: 1,
        },
      },
      animation: `${expandKey} linear forwards`,
      animationRange: 'entry',
      animationTimeline: 'view()',
      viewTimeline: `--solution-card-${index}`,
    };
    glowAnimationStyles = {
      transition: 'border-radius 0.3s',
      transform: 'scale(0.9)',
      [`@keyframes ${expandGlowKey}`]: {
        '0%': {
          transform: `scale(${startScale})`,
        },
        '22%': {
          transform: `scale(${startScale})`,
        },
        '100%': {
          transform: 'scale(1)',
        },
      },
      animation: `${expandGlowKey} linear forwards`,
      animationRange: 'entry',
      animationTimeline: 'view()',
    };
    gradientAnimationStyles = {
      [`@keyframes ${gradientKey}`]: isPotato
        ? {
            '49%': {
              opacity: 0,
            },
            '50%': {
              opacity: 1,
            },
          }
        : {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
      animation: `${gradientKey} linear forwards`,
      animationRange: 'entry',
      animationTimeline: `--solution-card-${index}`,
    };
  } else {
    animationStyles = {
      transition: 'transform 0.3s, border-radius 0.3s',
      transform: isPotato ? 'none' : `scale(${cardScaleValue})`,
      background:
        fromGradient != null ? gradientPropsToCss(fromGradient) : undefined,
    };
    glowAnimationStyles = {
      transition: 'transform 0.3s, border-radius 0.3s, opacity 0.3s',
      transform: isPotato ? 'none' : `scale(${imageScaleValue})`,
    };
    gradientAnimationStyles = {
      opacity: Math.round(percentElementInView / 100),
      transition: 'opacity 1.5s',
    };
  }

  const containerAnimationTimelineIndex = isLastCard ? index : index + 1;
  let cardExitAnimation = {};
  if (useChromiumStyles) {
    cardExitAnimation = {
      [`@keyframes exit-contents-${index}`]: {
        '0%': {
          transform: 'translateY(0)',
        },
        '100%': {
          transform: `translateY(${isLastCard ? 0 : 60}vh)`,
        },
      },
      animation: `exit-contents-${index} linear forwards`,
      animationRange: !isLastCard && 'entry',
      animationRangeStart: isLastCard && 'entry calc(100vh)',
      animationRangeEnd: isLastCard && 'exit calc(100vh)',
      animationTimeline: `--solution-card-${containerAnimationTimelineIndex}`,
    };
  }

  return (
    <Box
      component='section'
      sx={{
        height: '100vh',
        position: 'relative',
        top: 0,
        '&:not(:first-of-type)': {
          marginTop: `${SCROLL_OFFSET}px`,
        },
        ...cardExitAnimation,
        ...containerSx,
      }}
      ref={ref}
    >
      {useChromiumStyles && (
        <GlobalStyles
          styles={`
          @property ${percentScrolledKey} {
            syntax: '<number>';
            initial-value: 0;
            inherits: false;
          }
      `}
        />
      )}
      {!isPotato && (
        <Image
          src='https://images.ctfassets.net/ss5kfr270og3/7q1mJPw6bmeeUruloUUcxa/d69826c7922a24d66660ccd4e732a79a/3621384b921832f85bca12c7d2a4f5da?fm=webp&q=1'
          alt='rainbowgradient'
          width='400'
          height='400'
          sx={{
            position: isPotato ? 'relative' : 'absolute',
            top: 0,
            left: 0,
            height: '80%',
            width: '100%',
            filter: 'blur(40px)',
            zIndex: isPotato ? 100 : -1,
            opacity: 1,
            ...glowAnimationStyles,
          }}
        />
      )}
      <Box
        sx={{
          borderTopLeftRadius:
            isPotato && index !== 0
              ? 0
              : percentElementInView >= 100 && !isPotato
              ? 0
              : '40px',
          borderTopRightRadius:
            isPotato && index !== 0
              ? 0
              : percentElementInView >= 100 && !isPotato
              ? 0
              : '40px',
          borderBottomLeftRadius: isPotato && isLastCard && '40px',
          borderBottomRightRadius: isPotato && isLastCard && '40px',
          display: 'flex',
          position: 'absolute',
          top: 0,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          zIndex: 100,
          '&:last-child': {
            marginBottom: 0,
          },
          overflow: 'hidden',
          ...animationStyles,
          ...sx,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: gradientPropsToCss(toGradient),
            opacity: 0,
            // Needs to go below product shot glow
            zIndex: -2,
            ...gradientAnimationStyles,
          }}
        />
        {typeof children === 'function'
          ? children({
              index,
              percentElementInView,
              getScaledValue,
              canReverse,
              pixelsScrolledPastInitialTop,
              isLastCard,
            })
          : children}
      </Box>
    </Box>
  );
};

export default StickyCard;
