import React, { useRef, useLayoutEffect, useMemo } from 'react';
import { Box } from 'src/components-v2/Layout';
import { useToggleContext } from './Toggle';
import throttle from 'lodash/throttle';
import useHasMouse from 'src/hooks/useHasMouse';
import { useReducedMotion } from '@react-spring/web';
import useIsPotato from 'src/hooks/useIsPotato';
import styled from 'threads5/styles/styled';

// Styled canvas component
const Canvas = styled('canvas')(() => {
  return {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  };
});

// Move static drawing functions outside the component
const drawFingerprintPaths = (
  mainContext,
  paths,
  isPsychedelic,
  scale,
  translateX,
  translateY,
) => {
  mainContext.clearRect(
    0,
    0,
    mainContext.canvas.width,
    mainContext.canvas.height,
  );
  mainContext.save();
  mainContext.translate(translateX, translateY);
  mainContext.scale(scale, scale);
  mainContext.fillStyle = 'rgba(0, 128, 255, 0)';
  mainContext.strokeStyle = isPsychedelic ? '#8B8BF9' : '#A2F87F';
  mainContext.lineWidth = 1 / scale;

  paths.forEach((path) => {
    if (path) {
      mainContext.fill(path);
      mainContext.stroke(path);
    }
  });

  mainContext.restore();
};

const drawMasks = (
  maskContext,
  cursorPos,
  maskCanvasWidth,
  maskCanvasHeight,
  deviceType,
  devicePixelRatio,
) => {
  maskContext.clearRect(0, 0, maskCanvasWidth, maskCanvasHeight);

  // Dynamic Gradient Mask (follows cursor)
  const { x: gradientX, y: gradientY } = cursorPos;
  const innerRadius = 1 / devicePixelRatio;
  const outerRadius = deviceType === 'desktop' ? 450 / devicePixelRatio : 0;

  const dynamicGradient = maskContext.createRadialGradient(
    gradientX,
    gradientY,
    innerRadius,
    gradientX,
    gradientY,
    outerRadius,
  );

  dynamicGradient.addColorStop(0, 'rgba(0, 0, 0, 0.6)');
  dynamicGradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

  maskContext.fillStyle = dynamicGradient;
  maskContext.beginPath();
  maskContext.arc(gradientX, gradientY, outerRadius, 0, Math.PI * 2);
  maskContext.fill();

  const deviceTypeToScale = {
    mobile: 0.5,
    tablet: 0.75,
    desktop: 1,
  };

  // Static Gradient Masks
  const staticMasks = [
    {
      x: 100,
      y: 100,
      innerRadius: 10 / devicePixelRatio,
      outerRadius: (300 * deviceTypeToScale[deviceType]) / devicePixelRatio,
      color: 'rgba(0, 0, 0, 0.2)',
    },
    {
      x: (maskCanvasWidth - 100) / devicePixelRatio,
      y: 100 / devicePixelRatio,
      innerRadius: 40 / devicePixelRatio,
      outerRadius: (300 * deviceTypeToScale[deviceType]) / devicePixelRatio,
      color: 'rgba(0, 0, 0, 0.1)',
    },
    {
      x: maskCanvasWidth / 2 / devicePixelRatio,
      y: (maskCanvasHeight - 100) / devicePixelRatio,
      innerRadius: 1 / devicePixelRatio,
      outerRadius: (400 * deviceTypeToScale[deviceType]) / devicePixelRatio,
      color: 'rgba(0, 0, 0, 0.2)',
    },
  ];

  staticMasks.forEach(({ x, y, innerRadius, outerRadius, color }) => {
    const staticGradient = maskContext.createRadialGradient(
      x,
      y,
      innerRadius,
      x,
      y,
      outerRadius,
    );

    staticGradient.addColorStop(0, color);
    staticGradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

    maskContext.fillStyle = staticGradient;
    maskContext.beginPath();
    maskContext.arc(x, y, outerRadius, 0, Math.PI * 2);
    maskContext.fill();
  });
};

const applyMask = (mainContext, maskCanvas) => {
  if (maskCanvas.width > 0 && maskCanvas.height > 0) {
    mainContext.save();
    mainContext.globalCompositeOperation = 'destination-in';
    mainContext.drawImage(
      maskCanvas,
      0,
      0,
      mainContext.canvas.width,
      mainContext.canvas.height,
    );
    mainContext.restore();
  } else {
    // eslint-disable-next-line no-console
    console.warn('Cannot apply mask: maskCanvas has zero width or height.');
  }
};

const Thumbprint = ({ svgDetails: { fingerprint } }) => {
  const { isPsychedelic } = useToggleContext();
  const mainCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const boxRef = useRef(null);
  const targetCursorPosition = useRef({ x: 100, y: 390 });
  const currentCursorPosition = useRef({ x: 100, y: 390 });
  const hasMouse = useHasMouse();
  const prefersReducedMotion = useReducedMotion();
  const isPotato = useIsPotato();

  // Cache Path2D objects for the desktop fingerprint
  const cachedPaths = useMemo(() => {
    // Check if Path2D is available
    if (typeof window === 'undefined' || typeof Path2D === 'undefined') {
      return [];
    }

    if (fingerprint && fingerprint.paths) {
      return fingerprint.paths.map((data) => {
        return data ? new Path2D(data) : null;
      });
    }
    return [];
  }, [fingerprint]);

  useLayoutEffect(() => {
    const mainCanvas = mainCanvasRef.current;
    const maskCanvas = maskCanvasRef.current;
    const parent = boxRef.current;

    if (!mainCanvas || !maskCanvas || !parent) {
      // eslint-disable-next-line no-console
      console.warn('Canvas or parent Box not available.');
      return;
    }

    const mainContext = mainCanvas.getContext('2d');
    const maskContext = maskCanvas.getContext('2d');
    const devicePixelRatio = window.devicePixelRatio || 1;

    let cachedScale = null;
    let cachedTranslateX = null;
    let cachedTranslateY = null;

    const getDeviceType = () => {
      if (window.matchMedia('(max-width: 639px)').matches) return 'mobile';
      if (
        window.matchMedia('(min-width: 640px) and (max-width: 1023px)').matches
      )
        return 'tablet';
      return 'desktop';
    };

    const drawMaskedPaths = (cursorPos) => {
      const deviceType = getDeviceType();
      const fingerprintData = fingerprint;
      if (!fingerprintData) {
        // eslint-disable-next-line no-console
        console.warn('No fingerprint data available.');
        return;
      }

      const { width, height, paths } = fingerprintData;
      if (!width || !height || !paths) {
        // eslint-disable-next-line no-console
        console.warn('Incomplete fingerprint data.');
        return;
      }

      if (!cachedScale || !cachedTranslateX || !cachedTranslateY) {
        const containerWidth = parent.offsetWidth;
        const containerHeight = parent.offsetHeight;
        cachedScale = Math.max(
          containerWidth / width,
          containerHeight / height,
        );
        cachedTranslateX = (containerWidth - width * cachedScale) / 2;
        cachedTranslateY = (containerHeight - height * cachedScale) / 2;
      }

      // Use cachedPaths for drawing
      drawFingerprintPaths(
        mainContext,
        cachedPaths,
        isPsychedelic,
        cachedScale,
        cachedTranslateX,
        cachedTranslateY,
      );

      drawMasks(
        maskContext,
        cursorPos,
        parseInt(mainCanvas.style.width, 10) || mainCanvas.width,
        parseInt(mainCanvas.style.height, 10) || mainCanvas.height,
        deviceType,
        devicePixelRatio,
      );

      applyMask(mainContext, maskCanvas);
    };

    const resizeCanvas = throttle(() => {
      const { offsetWidth, offsetHeight } = parent;
      if (offsetWidth === 0 || offsetHeight === 0) {
        // eslint-disable-next-line no-console
        console.warn('Parent Box has zero width or height.');
        return;
      }

      mainCanvas.width = offsetWidth * devicePixelRatio;
      mainCanvas.height = offsetHeight * devicePixelRatio;
      mainCanvas.style.width = `${offsetWidth}px`;
      mainCanvas.style.height = `${offsetHeight}px`;

      maskCanvas.width = offsetWidth * devicePixelRatio;
      maskCanvas.height = offsetHeight * devicePixelRatio;
      maskCanvas.style.width = `${offsetWidth}px`;
      maskCanvas.style.height = `${offsetHeight}px`;

      mainContext.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
      maskContext.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

      cachedScale = null;
      cachedTranslateX = null;
      cachedTranslateY = null;

      drawMaskedPaths(currentCursorPosition.current);
      mainCanvas.style.opacity = 1;
    }, 16);

    resizeCanvas();

    const observer = new ResizeObserver(() => {
      resizeCanvas();
    });
    observer.observe(parent);

    let animationFrameId;
    let handleMouseMove;
    let animate;

    if (hasMouse && !prefersReducedMotion && !isPotato) {
      animate = () => {
        const { x: currentX, y: currentY } = currentCursorPosition.current;
        const { x: targetX, y: targetY } = targetCursorPosition.current;

        const deltaX = targetX - currentX;
        const deltaY = targetY - currentY;
        const easing = 0.1;

        currentCursorPosition.current.x += deltaX * easing;
        currentCursorPosition.current.y += deltaY * easing;

        drawMaskedPaths(currentCursorPosition.current);

        if (Math.abs(deltaX) > 0.5 || Math.abs(deltaY) > 0.5) {
          animationFrameId = requestAnimationFrame(animate);
        } else {
          animationFrameId = null;
        }
      };

      handleMouseMove = throttle((e) => {
        const rect = mainCanvas.getBoundingClientRect();
        targetCursorPosition.current = {
          x: e.clientX / devicePixelRatio - rect.left,
          y: e.clientY / devicePixelRatio - rect.top,
        };
        if (!animationFrameId) {
          animationFrameId = requestAnimationFrame(animate);
        }
      }, 16);

      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (handleMouseMove)
        window.removeEventListener('mousemove', handleMouseMove);
      observer.disconnect();
      resizeCanvas.cancel();
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, [
    isPsychedelic,
    hasMouse,
    prefersReducedMotion,
    isPotato,
    fingerprint,
    cachedPaths,
  ]);

  return (
    <Box
      ref={boxRef}
      sx={{
        position: 'absolute',
        height: '100%',
        bottom: '0',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Canvas ref={mainCanvasRef} />
      <Canvas ref={maskCanvasRef} sx={{ display: 'none' }} />
    </Box>
  );
};

export default Thumbprint;
