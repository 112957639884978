import * as React from 'react';
import styled from 'threads5/styles/styled';

import {
  Section,
  Container,
  Row,
  Column,
  Stack,
  Spacer,
} from 'src/components-v2/Layout';
import HeroMarquee from './HeroMarquee';
import Thumbprint from './Thumbprint';
import Heading1 from './Heading';
import Ben from './Ben';
import Toggle, { useToggleContext } from './Toggle';
import { animated, useSpring } from '@react-spring/web';
import useGradientText from 'src/hooks/useGradientText';
import { useCriticalRenderContext } from 'src/hooks/useCriticalRenderContext';
import Subheading from './Subheading';
import useIsPotato from 'src/hooks/useIsPotato';

const SectionHeader = styled(Section)<{
  onMouseMove?: (e: React.MouseEvent) => void;
  haveAllCriticalAssetsLoaded?: boolean;
  isPotato?: boolean;
}>(({ theme, haveAllCriticalAssetsLoaded, isPotato }) => {
  const potatoHeightOffset = isPotato ? 40 : 0;
  return {
    position: 'relative',
    background:
      'linear-gradient(-210deg, #07578C 27.07%, #0E94D8 59.71%, #42F0CD 92.45%)',
    overflow: 'hidden',
    overflowX: 'hidden',
    cursor: haveAllCriticalAssetsLoaded ? 'default' : 'wait',
    [theme.breakpoints.up('xs')]: {
      height: `${552 + potatoHeightOffset}px`,
    },
    [theme.breakpoints.up(400)]: {
      height: `${532 + potatoHeightOffset}px`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `${872 + potatoHeightOffset}px`,
    },
    [theme.breakpoints.up('md')]: {
      height: `${923 + potatoHeightOffset}px`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `${923 + potatoHeightOffset}px`,
    },
    [theme.breakpoints.up('xl')]: {
      height: `${929 + potatoHeightOffset}px`,
    },
  };
});

const Hero = (props): React.ReactElement => {
  const isPotato = useIsPotato();
  const { isPsychedelic } = useToggleContext();
  const { elementRef, handleMouseMove, parentRef } = useGradientText<
    HTMLHeadingElement,
    HTMLDivElement
  >({
    gradientColors: isPsychedelic
      ? '#E5D3F8 9.35%, #10D0B7 59.18%, #97D091 101.62%'
      : '#86EF5A 0.86%, #10D0B7 38.09%, #FFFFFF 69.81%',
  });
  const { opacity } = useSpring({
    opacity: isPsychedelic ? 1 : 0,
  });
  const { haveAllCriticalAssetsLoaded } = useCriticalRenderContext();

  return (
    <SectionHeader
      ref={parentRef}
      onMouseMove={handleMouseMove}
      component='header'
      haveAllCriticalAssetsLoaded={haveAllCriticalAssetsLoaded}
      isPotato={isPotato}
    >
      <animated.div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          background: 'linear-gradient(95deg, #01172E 27.81%, #012E37 76.6%)',
          opacity,
        }}
      />
      <Container sx={{ height: '100%', mt: '7px' }}>
        <Row sx={{ height: { xs: 'auto', md: '100%' } }}>
          <Column xs={22} xl={13} xsOffset={1}>
            <Stack direction='column' justifyContent='center' height='100%'>
              <Spacer
                sx={{ height: { xs: '110px', sm: '140px', md: '260px' } }}
              />
              {isPsychedelic ? (
                <Heading1
                  key={'psychedelic'}
                  ref={elementRef}
                  sx={{
                    background:
                      'radial-gradient(circle at top left, #E5D3F8 9.35%, #10D0B7 59.18%, #97D091 101.62%)',
                  }}
                />
              ) : (
                <Heading1
                  key={'default'}
                  ref={elementRef}
                  sx={{
                    background:
                      'radial-gradient(circle at top left, #86EF5A 0.86%, #10D0B7 38.09%, #FFFFFF 69.81%)',
                  }}
                />
              )}
              <Subheading />
              <Spacer sx={{ height: { xs: '35px', sm: '44px', md: '50px' } }} />
              <Toggle sx={{ display: { xs: 'none', sm: 'flex' } }} />
              <HeroMarquee />
              <Spacer sx={{ height: { xs: '56px', sm: '0' } }} />
              <Toggle
                sx={{
                  display: { xs: 'flex', sm: 'none' },
                }}
              />
              <Spacer sx={{ height: { xs: '60px', sm: '170px' } }} />
            </Stack>
          </Column>
          <Column
            xl={10}
            sx={{
              position: { xs: 'static', xl: 'relative' },
            }}
          >
            <Ben />
          </Column>
        </Row>
      </Container>
      <Thumbprint key='hero' {...props} />
    </SectionHeader>
  );
};

export default Hero;
