import Box from 'threads5/Box';
import CardPile from './CardPile';
import StickyCard, { StickyCardChildrenProps } from './StickyCard';
import StickyCardContents from './StickyCardContents';
import React, { useRef } from 'react';
import { useMediaQuery } from '@mui/system';
import theme from 'src/components-v2/theme';
import useIsPotato from 'src/hooks/useIsPotato';
import useWorkerGuilloche from 'src/hooks/useWorkerGuilloche';
import CardGuilloche from './CardGuilloche';

const SolutionCards = (props) => {
  const isPotato = useIsPotato();

  const belowLg = useMediaQuery(theme.breakpoints.down('lg'));
  const belowMd = useMediaQuery(theme.breakpoints.down('md'));
  const belowSm = useMediaQuery(theme.breakpoints.down('sm'));
  let guillocheScale = 1;
  if (belowSm) {
    guillocheScale = 2.5;
  } else if (belowMd) {
    guillocheScale = 2;
  } else if (belowLg) {
    guillocheScale = 1.5;
  }
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);
  const card4Ref = useRef(null);
  const card5Ref = useRef(null);

  const canvas1 = React.useRef<HTMLCanvasElement>(null);
  useWorkerGuilloche({
    canvasRef: canvas1,
    svgPaths: props.svgDetails.dune.paths,
    svgWidth: props.svgDetails.dune.width,
    svgHeight: props.svgDetails.dune.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    id: 'canvas1',
    scale: { xs: 1.5, xxl: 2 },
    y: { xs: -20, sm: -5 },
    rootMargin: '-30% 0px 0px 0px',
  });
  const canvas2 = React.useRef<HTMLCanvasElement>(null);
  useWorkerGuilloche({
    canvasRef: canvas2,
    svgPaths: props.svgDetails.ribbon2.paths,
    svgWidth: props.svgDetails.ribbon2.width,
    svgHeight: props.svgDetails.ribbon2.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: false,
    zAxisWave: false,
    intersectionThreshold: 0,
    id: 'canvas2',
    scale: { xs: 1, sm: 1.5, xxl: 2 },
    y: { xs: 0, sm: -15, md: 5, lg: -4, xxl: -40 },
    rootMargin: '-30% 0px 0px 0px',
  });
  const canvas3 = React.useRef<HTMLCanvasElement>(null);
  useWorkerGuilloche({
    canvasRef: canvas3,
    svgPaths: props.svgDetails.ribbon.paths,
    svgWidth: props.svgDetails.ribbon.width,
    svgHeight: props.svgDetails.ribbon.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: false,
    zAxisWave: false,
    id: 'canvas3',
    scale: { xs: 1, sm: 2.5, md: 2, lg: 2.5, xxl: 4 },
    y: { xs: -16, sm: -16 },
    rootMargin: '-30% 0px 0px 0px',
  });
  const canvas4 = React.useRef<HTMLCanvasElement>(null);
  useWorkerGuilloche({
    canvasRef: canvas4,
    svgPaths: props.svgDetails.dune.paths,
    svgWidth: props.svgDetails.dune.width,
    svgHeight: props.svgDetails.dune.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    intersectionThreshold: 0,
    id: 'canvas4',
    scale: { xs: 1.5, xxl: 3 },
    y: { xs: -5, sm: -8, md: -10, xl: -10, xxl: -8 },
    rootMargin: '-30% 0px 0px 0px',
  });
  const canvas5 = React.useRef<HTMLCanvasElement>(null);
  useWorkerGuilloche({
    canvasRef: canvas5,
    svgPaths: props.svgDetails.dune2.paths,
    svgWidth: props.svgDetails.dune2.width,
    svgHeight: props.svgDetails.dune2.height,
    intensity: 0.375,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    id: 'canvas5',
    scale: { xs: 1, md: 1, xl: 2, xxl: 3 },
    y: { xs: 1, sm: 17, md: 15, xl: 25, xxl: 15 },
    rootMargin: '-30% 0px 0px 0px',
  });

  return (
    <CardPile
      sx={{
        paddingTop: 0,
        background:
          'linear-gradient(176deg, #E5FFDD 3.31%, #D0FBFC 28.98%, #F5F5FF 78.61%)',
      }}
    >
      <StickyCard
        fromGradient={{
          degree: 48,
          colorA: '#088181',
          colorB: '#156843',
          colorAPercent: 0,
          colorBPercent: 100,
        }}
        toGradient={{
          degree: 5,
          colorA: '#10D1B7',
          colorB: '#156843',
          colorAPercent: 1.29,
          colorBPercent: 88.56,
        }}
        sx={{
          boxShadow:
            isPotato && '0px -30px 50px -10px rgba(124, 240, 66, 0.30)',
        }}
        index={0}
      >
        {(stickyCardChildrenProps: StickyCardChildrenProps) => {
          return (
            <>
              <CardGuilloche
                sx={{
                  top: {
                    xs: '90vh',
                    sm: '30vh',
                  },
                  translate: {
                    xs: '0 -150%',
                    sm: '0 -70%',
                  },
                }}
                imageSrc={
                  belowSm
                    ? 'https://images.ctfassets.net/ss5kfr270og3/6oknUmGATgtfwMXPJxHZfd/9f5af560dba066afb6b1a26a6df7749b/Guilloche1.png?fm=webp&q=80'
                    : 'https://images.ctfassets.net/ss5kfr270og3/1V7QNxcDwygkWDGeZvgWVT/fb004e33fe0f8eb11bb28543c1faf845/guilloche1_desktop.png?fm=webp&q=80'
                }
                imageSx={{
                  top: { xs: '-7%', sm: 0 },
                }}
                canvasRef={canvas1}
                guillocheScaleMultiplier={guillocheScale}
                {...stickyCardChildrenProps}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <StickyCardContents
                  index={stickyCardChildrenProps.index}
                  assetSrc={
                    belowLg
                      ? 'https://images.ctfassets.net/ss5kfr270og3/3vNmzGqlRtuPe9txT67Lkl/6ea950d24dbadf3dfdc2a28a000d7e1f/Layer_product_shot.png?fm=webp&q=50&w=1320&h=2860'
                      : 'https://images.ctfassets.net/ss5kfr270og3/5mCUhfE9jCqMxP4wTn6CKE/7e903929fbdd08191b9960789052451e/Layer_product_shot.png?fm=webp&q=50&w=1652&h=3576'
                  }
                  heading='The internet’s fastest financial onboarding'
                  subheading='Increase conversion, fight fraud, and onboard customers to your app or service in seconds.'
                  buttonHref='/layer'
                  buttonVariant='green'
                  buttonText='See onboarding in action'
                  buttonSx={{
                    background:
                      'linear-gradient(92deg, #FFF 0.86%, #D5FFD2 102.51%)',
                  }}
                  {...stickyCardChildrenProps}
                />
              </Box>
            </>
          );
        }}
      </StickyCard>
      <StickyCard
        fromGradient={{
          degree: 48,
          colorA: '#088181',
          colorB: '#006477',
          colorAPercent: 0,
          colorBPercent: 100,
        }}
        toGradient={{
          degree: 180,
          colorA: '#10D1B7',
          colorB: '#00819A',
          colorAPercent: -20.88,
          colorBPercent: 57.1,
        }}
        sx={{
          boxShadow:
            isPotato && '0px -30px 50px -10px rgba(182, 251, 233, 0.50)',
        }}
        index={1}
        percentElementInViewRef={card2Ref}
      >
        {(stickyCardChildrenProps: StickyCardChildrenProps) => {
          const guillocheScaleMultiplier = guillocheScale + 0.65;
          return (
            <>
              <CardGuilloche
                sx={{
                  maxWidth: {
                    lg: '200rem',
                  },
                  left: '50%',
                  top: '90vh',
                  translate: {
                    xs: '-50% -165%',
                    sm: '-50% -140%',
                    md: '-50% -145%',
                    lg: '-50% -140%',
                  },
                }}
                canvasRef={canvas2}
                guillocheScaleMultiplier={guillocheScaleMultiplier}
                imageSrc={
                  belowSm
                    ? 'https://images.ctfassets.net/ss5kfr270og3/4anrYAVBI6NeSZnwPKKgWz/8fc8adb64fa5cfddc6bde56f0057728d/Guilloche2.png?fm=webp&q=80'
                    : 'https://images.ctfassets.net/ss5kfr270og3/4R3csM28kPTPcwlOJyt20X/5cd68bbeec42dec9aa26518ba09291d9/guilloche2_desktop.png?fm=webp&q=80'
                }
                imageSx={{
                  top: { xs: '-6%', sm: 0 },
                }}
                {...stickyCardChildrenProps}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <StickyCardContents
                  index={stickyCardChildrenProps.index}
                  assetSrc={
                    belowLg
                      ? 'https://images.ctfassets.net/ss5kfr270og3/3UEYAL4m0FdYlq7yS4braM/b6cf174c557907e061e03c710c1615f5/billPayProductShotMobile.png?fm=webp&q=50&w=1322&h=2862'
                      : 'https://images.ctfassets.net/ss5kfr270og3/6Ax7Fx3NQVIgSLOyEtqOs6/73b1bdb6b20ecd916f6f24c8865d1f4f/billPayProductShot.png?fm=webp&q=50&w=1652&h=3576'
                  }
                  heading='Fast and secure payments'
                  subheading='Increase revenue with seamless bank payments designed to deliver higher conversion rates.'
                  buttonHref='/solutions/payments'
                  buttonVariant='blue'
                  buttonText='Explore payment solutions'
                  buttonSx={{
                    background:
                      'linear-gradient(246deg, #AEFFF3 15.6%, #FFF 88.69%)',
                  }}
                  {...stickyCardChildrenProps}
                />
              </Box>
            </>
          );
        }}
      </StickyCard>
      <StickyCard
        fromGradient={{
          degree: 48,
          colorA: '#00819A',
          colorB: '#633ECB',
          colorAPercent: 0,
          colorBPercent: 100,
        }}
        toGradient={{
          degree: 163,
          colorA: '#00A2C2',
          colorB: '#8358EF',
          colorAPercent: 11.31,
          colorBPercent: 89.99,
        }}
        sx={{
          boxShadow:
            isPotato && '0px -30px 50px -12px rgba(216, 194, 251, 0.80)',
        }}
        index={2}
        percentElementInViewRef={card3Ref}
      >
        {(stickyCardChildrenProps: StickyCardChildrenProps) => {
          const guillocheScaleMultiplier = belowSm
            ? 3.15
            : guillocheScale + 0.75;
          return (
            <>
              <CardGuilloche
                sx={{
                  top: '30vh',
                  translate: {
                    xs: '0 -37%',
                    md: '0 -35%',
                    lg: '0 -39%',
                  },
                }}
                canvasRef={canvas3}
                guillocheScaleMultiplier={guillocheScaleMultiplier}
                imageSrc={
                  belowSm
                    ? 'https://images.ctfassets.net/ss5kfr270og3/3c7HlGvpVpVn26Lp9P3AE1/6d0e82a804237e04e3a29e153b2b2fbd/Guilloche3.png?fm=webp&q=80'
                    : 'https://images.ctfassets.net/ss5kfr270og3/1kP1kR2GpKAQ0eWkOK2itr/d3ae360cf46ea4eb177d3dcdc8d6dea1/guilloche3_desktop.png?fm=webp&q=80'
                }
                imageSx={{
                  top: { xs: '-4%', sm: 0 },
                }}
                {...stickyCardChildrenProps}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <StickyCardContents
                  index={stickyCardChildrenProps.index}
                  assetSrc={
                    belowLg
                      ? 'https://images.ctfassets.net/ss5kfr270og3/1Yfy4i6CG7T0rTUUcko0Be/b40ac6c09320f7fae1be809d2ec0284b/fraudProductShotMobile.png?fm=webp&q=50&w=1424&h=2860'
                      : 'https://images.ctfassets.net/ss5kfr270og3/5dRT581AMyE4Mm5dCPkWs2/a0cd6faf4f42157d8d9f8b577d3d916a/fraudProductShot.png?fm=webp&q=100&w=2056&h=1788'
                  }
                  productShotSx={{
                    maxWidth: {
                      lg: '1728px',
                    },
                  }}
                  glowSx={{
                    left: {
                      xs: '11%',
                      lg: '16%',
                    },
                    width: {
                      xs: '79%',
                      lg: '68%',
                    },
                    top: {
                      xs: '52%',
                      lg: '56%',
                    },
                    height: {
                      xs: '46%',
                      lg: '44%',
                    },
                  }}
                  heading='Network-powered fraud signals'
                  subheading='Stop identity fraud, account takeovers, and risky payments with ML-powered fraud signals. '
                  buttonHref='/solutions/fraud-compliance'
                  buttonVariant='purple'
                  buttonText='See how we protect you'
                  buttonSx={{
                    background:
                      'linear-gradient(272deg, #E4E4FF 9.5%, #FFF 100%)',
                  }}
                  {...stickyCardChildrenProps}
                />
              </Box>
            </>
          );
        }}
      </StickyCard>
      <StickyCard
        fromGradient={{
          degree: 48,
          colorA: '#BB33D7',
          colorB: '#633ECB',
          colorAPercent: 0,
          colorBPercent: 100,
        }}
        toGradient={{
          degree: 7,
          colorA: '#E574FB',
          colorB: '#633ECB',
          colorAPercent: -3.96,
          colorBPercent: 94.51,
        }}
        sx={{
          boxShadow:
            isPotato && '0px -30px 50px -12px rgba(216, 194, 251, 0.60)',
        }}
        index={3}
        percentElementInViewRef={card4Ref}
      >
        {(stickyCardChildrenProps: StickyCardChildrenProps) => {
          const guillocheScaleMultiplier = belowSm ? 3.5 : guillocheScale + 0.5;
          return (
            <>
              <CardGuilloche
                sx={{
                  top: '30vh',
                  translate: { xs: '0 -60%', sm: '0 -40%', lg: '0 -60%' },
                }}
                canvasRef={canvas4}
                guillocheScaleMultiplier={guillocheScaleMultiplier}
                imageSrc={
                  belowSm
                    ? 'https://images.ctfassets.net/ss5kfr270og3/1Cl6gcehqfOe0VnruPgxVm/65da266e68bf7413b4c10eba23cda329/Guilloche4.png?fm=webp&q=80'
                    : 'https://images.ctfassets.net/ss5kfr270og3/4fDYpBJYkNuMr2OxlEj6Zk/256784c3c34a067606354462c02aa2dd/guilloche4_desktop.png?fm=webp&q=80'
                }
                imageSx={{
                  top: { xs: '-4%', sm: 0 },
                }}
                {...stickyCardChildrenProps}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <StickyCardContents
                  index={stickyCardChildrenProps.index}
                  assetSrc={
                    belowLg
                      ? 'https://images.ctfassets.net/ss5kfr270og3/6PsZfVfKyPMtqEh3EByJc5/2bc5a069662d96094a14125679a7c6a6/cashFlowProductShotMobile.png?fm=webp&q=50&w=1480&h=2860'
                      : 'https://images.ctfassets.net/ss5kfr270og3/4nVdQyCOkdbfhxEo8Vl5Q5/894732a052112498f61c76f2ce2f718a/cashFlowProductShot.png?fm=webp&q=100&w=2094&h=1788'
                  }
                  productShotSx={{
                    maxWidth: {
                      lg: '1728px',
                    },
                  }}
                  glowSx={{
                    left: {
                      xs: '7%',
                      lg: '16%',
                    },
                    width: {
                      xs: '89%',
                      lg: '68%',
                    },
                    top: {
                      xs: '56%',
                      lg: '56%',
                    },
                    height: {
                      xs: '41%',
                      lg: '45%',
                    },
                  }}
                  heading='Cash flow data for smarter underwriting'
                  subheading='Say “yes” to more qualified borrowers and reduce risk with real-time lending insights.'
                  buttonHref='/solutions/credit'
                  buttonVariant='pink'
                  buttonText='Tap into cash flow insights'
                  buttonSx={{
                    background:
                      'linear-gradient(272deg, #FBE5FF 0%, #FFF 100%)',
                  }}
                  {...stickyCardChildrenProps}
                />
              </Box>
            </>
          );
        }}
      </StickyCard>
      <StickyCard
        isLastCard
        fromGradient={{
          degree: 48,
          colorA: '#CE4553',
          colorB: '#8F29A3',
          colorAPercent: 0,
          colorBPercent: 100,
        }}
        toGradient={{
          degree: 0,
          colorA: '#FB7474',
          colorB: '#8F29A3',
          colorAPercent: -16.54,
          colorBPercent: 109.99,
        }}
        sx={{
          boxShadow:
            isPotato && '0px -30px 50px -12px rgba(242, 176, 255, 0.50)',
        }}
        index={4}
        percentElementInViewRef={card5Ref}
      >
        {(stickyCardChildrenProps: StickyCardChildrenProps) => {
          const guillocheScaleMultiplier = belowSm ? 5 : guillocheScale + 0.5;
          return (
            <>
              <CardGuilloche
                sx={{
                  top: '0vh',
                  translate: {
                    xs: '0 -15%',
                    sm: '0 -20%',
                    lg: '0 -30%',
                    xl: '0 -20%',
                  },
                }}
                canvasRef={canvas5}
                guillocheScaleMultiplier={guillocheScaleMultiplier}
                imageSrc={
                  belowSm
                    ? 'https://images.ctfassets.net/ss5kfr270og3/3jn6A0UzcdkRQcOnSHazgx/6aa8957d560978da2558074e8c8e9eee/guilloche5.png?fm=webp&q=80'
                    : 'https://images.ctfassets.net/ss5kfr270og3/65hFFpwADYfGH8z1pfaMsA/0553062cab2512f80de889fc2eb38c84/guilloche5_desktop.png?fm=webp&q=80'
                }
                imageSx={{
                  top: { xs: '-8%', sm: 0 },
                }}
                {...stickyCardChildrenProps}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <StickyCardContents
                  index={stickyCardChildrenProps.index}
                  assetSrc={
                    belowLg
                      ? 'https://images.ctfassets.net/ss5kfr270og3/1IbazyPL8DexOIqyi2jp0I/76d2d8316844677df038b7e342299402/openFinanceProductShotMobile.png?fm=webp&q=50&w=1412&h=2950'
                      : 'https://images.ctfassets.net/ss5kfr270og3/NfmvfB7TrXO8gGKcXLDx5/43616a1ae7a75690bfb198655f811c22/openFinanceProductShot.png?fm=webp&q=50&w=3392&h=3576'
                  }
                  glowSx={{
                    left: { lg: '22%' },
                    width: { xs: '100%', lg: '52%' },
                  }}
                  heading='Open finance solutions for everyone'
                  subheading='Meet open banking requirements and make it easy for customers to connect to the financial apps they need.'
                  buttonHref='/solutions/open-finance'
                  buttonVariant='pink'
                  buttonText='Simplify data connectivity'
                  buttonSx={{
                    background:
                      'linear-gradient(272deg, #FFEEEF 0%, #FFF 100%)',
                  }}
                  subheadingSx={{
                    width: '620px',
                  }}
                  {...stickyCardChildrenProps}
                />
              </Box>
            </>
          );
        }}
      </StickyCard>
    </CardPile>
  );
};

export default SolutionCards;
