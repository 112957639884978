import { useEffect, useState } from 'react';
import useIsPotato from './useIsPotato';

const useShowGuillocheImage = () => {
  const [showImage, setShowImage] = useState(false);
  const isPotato = useIsPotato();
  useEffect(() => {
    if (isPotato) {
      // This is a hack to get around one tick where the guilloche hook
      // attempts to draw to the canvas when it gets removed from the DOM.
      setTimeout(() => {
        setShowImage(true);
      }, 0);
    }
  }, [isPotato]);

  return showImage;
};

export default useShowGuillocheImage;
