import { StickyCardChildrenProps } from './StickyCard';
import Box from 'threads5/Box';
import { Image } from 'src/components-v2/Media';
import React from 'react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

interface Props extends StickyCardChildrenProps {
  sx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
  glowSx?: SxProps<Theme>;
  src: string;
  alt: string;
  width: number;
  height: number;
  disableGlow?: boolean;
  disableReverse?: boolean;
}

const ProductShot: React.FC<Props> = ({
  alt,
  src,
  width,
  height,
  sx,
  imgSx,
  glowSx,
  disableGlow,
  getScaledValue,
  percentElementInView,
  canReverse,
  disableReverse = false,
}) => {
  return (
    <Box
      sx={{
        height: '45vh',
        position: 'relative',
        ...sx,
      }}
    >
      <Image
        src='https://images.ctfassets.net/ss5kfr270og3/7q1mJPw6bmeeUruloUUcxa/d69826c7922a24d66660ccd4e732a79a/3621384b921832f85bca12c7d2a4f5da?fm=webp&q=1'
        alt='iphone'
        width={width}
        height={height}
        sx={{
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          maxHeight: sx['height'] ?? '47vh',
          filter: 'blur(40px)',
          zIndex: -1,
          opacity: disableGlow ? 0 : 0.5,
          ...glowSx,
        }}
      />
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        sx={{
          height: '100%',
          width: 'auto',
          maxHeight: '100vh',
          objectFit: 'contain',
          maxWidth: {
            xs: '200%',
            md: '100%',
          },
          ...imgSx,
        }}
      />
    </Box>
  );
};

export default ProductShot;
