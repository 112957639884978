import styled from 'threads5/styles/styled';
import React from 'react';

export const Number = styled('div')(({ theme }: { theme }) => {
  return {
    background: 'linear-gradient(254deg, #6748D8 12.14%, #B14EDB 96.16%)',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 300,
    fontSize: '7rem',
    fontFamily: 'Bowery Street',
    letterSpacing: '-3.5px',
    lineHeight: '94%',
    [theme.breakpoints.down('md')]: {
      color: '#633ECB',
      WebkitTextFillColor: 'unset',
      fontSize: '7rem',
      lineHeight: '94%',
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: '4rem',
      letterSpacing: '-2px',
    },
  };
});

const CountingNumber = ({
  incrementOverride,
  number,
  speedMs = 10,
  startNumber = 0,
  suffix,
}: {
  incrementOverride?: number;
  number: number;
  speedMs?: number;
  startNumber?: number;
  suffix?: string;
}) => {
  const ticks = Math.round(1750 / speedMs);
  const increment = incrementOverride || Math.max(1, number / ticks);
  const [count, setCount] = React.useState(startNumber);
  const [shouldCount, setShouldCount] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const onObserve = (entries) => {
    // allow count if visible
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setShouldCount(true);
      }
    });
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(onObserve, {
      rootMargin: `0px 0px 0px 0px`,
      threshold: 1.0,
    });
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  });

  React.useEffect(() => {
    if (count >= number) {
      return;
    }

    const interval = setInterval(() => {
      if (!shouldCount) return;
      setCount((prevCount) => {
        const nextCount = prevCount + increment;

        if (nextCount >= number) {
          setShouldCount(false);
        }
        return Math.min(nextCount, number);
      });
    }, speedMs);
    return () => {
      return clearInterval(interval);
    };
  }, [increment, number, shouldCount]);

  return (
    <Number
      ref={ref}
      sx={{
        display: 'inline-block',
        marginBottom: {
          xs: '0.8rem',
          md: 0,
        },
      }}
    >
      {Math.round(count)}
      {suffix}
    </Number>
  );
};

export default CountingNumber;
