import * as React from 'react';
import styled from 'threads5/styles/styled';
import PlaidTheme from 'threads5/themes/theme';
import { useCriticalRenderContext } from 'src/hooks/useCriticalRenderContext';
import SxProps from 'threads5/themes/sx-props';

const StyledHeading = styled('h1')<{ haveAllCriticalAssetsLoaded?: boolean }>(
  ({ theme, haveAllCriticalAssetsLoaded }) => {
    return {
      color: 'transparent',
      fontFamily: theme.typography.plaidSans,
      fontSize: '7.6rem',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '-3.4px',
      lineHeight: '112%',
      margin: '0 0 24px 0 !important',
      transition: 'opacity 0.3s ease-in-out',
      width: '907px',
      zIndex: 2,
      opacity: haveAllCriticalAssetsLoaded ? 1 : 0,
      [theme.breakpoints.down('xl')]: {
        fontSize: '7.0rem',
        letterSpacing: '-2.8px',
        width: '666px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '7.0rem',
        lineHeight: '110%',
        letterSpacing: '-2.8px',
        width: '666px',
        margin: '0 0 16px 0 !important',
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: '4.0rem',
        lineHeight: '100%',
        letterSpacing: '-1.6px',
        width: '326px',
        margin: '0 0 8px 0 !important',
      },
    };
  },
);

const Heading1 = React.forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithRef<'h1'> & { sx?: SxProps<PlaidTheme> }
>(({ sx, ...props }, ref) => {
  const { isPlaidSansLoaded } = useCriticalRenderContext();

  return (
    <StyledHeading
      ref={ref}
      haveAllCriticalAssetsLoaded={isPlaidSansLoaded}
      style={{
        backgroundClip: 'text',
      }}
      sx={sx}
      {...props}
    >
      Turn data into revolutionary financial products
    </StyledHeading>
  );
});
Heading1.displayName = 'Heading1';

export default Heading1;
