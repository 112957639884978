import { useEffect, useState } from 'react';

const useIsChromium = () => {
  const [isChromium, setIsChromium] = useState(false);

  useEffect(() => {
    setIsChromium(typeof window !== 'undefined' && !!window.chrome);
  }, [typeof window]);

  return isChromium;
};

export default useIsChromium;
