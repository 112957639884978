import React, { forwardRef, RefObject } from 'react';
import { default as ReactMarquee } from 'react-fast-marquee';
import { Typography } from 'src/components-v2/DataDisplay';
import { Box, Column, Row, Stack } from 'src/components-v2/Layout';
import { Image } from 'src/components-v2/Media';
import useWorkerGuilloche from 'src/hooks/useWorkerGuilloche';
import FloatingMoment, { MomentProps } from './FloatingMoment';
import CountingNumber, { Number } from './CountingNumber';
import styled from 'threads5/styles/styled';
import NotificationMoment from './NotificationMoment';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import useShowGuillocheImage from 'src/hooks/useShowGuillocheImage';
import { ImageSource } from 'src/components-v2/Media/Image/Image.types';

const Heading2 = styled('h2')(({ theme }) => {
  return {
    fontFamily: theme.typography.plaidSans,
    color: '#02294B',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    fontSize: '7rem',
    fontWeight: 500,
    background: 'linear-gradient(254deg, #B14EDB 11.74%, #6748D8 100%)',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '120%',
    letterSpacing: '-2.8px',
    [theme.breakpoints.down('md')]: {
      fontSize: '5rem',
      lineHeight: '110%',
      letterSpacing: '-2px',
      background: 'linear-gradient(254deg, #B14EDB 35.74%, #6748D8 100%)',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      paddingRight: '5rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '3rem',
      lineHeight: '120%',
      letterSpacing: '-1.6px',
      background: 'linear-gradient(272deg, #FBB 0%, #D06AEA 50%, #565490 100%)',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  };
});

const Heading3 = styled('h3')(({ theme }) => {
  return {
    fontFamily: theme.typography.plaidSans,
    fontSize: '3rem',
    fontWeight: 500,
    color: '#02294B',
    lineHeight: '94%',
    letterSpacing: '-1.2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5rem',
      fontWeight: 400,
      lineHeight: '94%',
      letterSpacing: '-1.4px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.8rem',
      fontWeight: 500,
      lineHeight: '94%',
      letterSpacing: '-0.72px',
    },
  };
});

const Paragraph = styled('p')(({ theme }) => {
  return {
    fontSize: '2rem',
    color: '#02294B',
    lineHeight: '134%',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      lineHeight: '130%',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.6rem',
      lineHeight: '134%',
    },
  };
});

const GuillocheCanvas = styled('canvas')(({ theme }) => {
  return {
    paddingRight: '6rem',
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'transparent',
    [theme.breakpoints.up('lg')]: {
      top: '-80vh',
      left: '-13vw',
      marginRight: '40vw',
      height: '250vh',
    },
    [theme.breakpoints.only('md')]: {
      top: '-10vh',
      left: '-28vw',
    },
    [theme.breakpoints.down('md')]: {
      top: '5vh',
      left: '-25vw',
    },
    [theme.breakpoints.only('xs')]: {
      top: '-5vh',
      left: '-35vw',
    },
  };
});

const Guilloche = forwardRef(
  (
    {
      imageSrc,
      showImage,
      sources,
    }: { imageSrc: string; showImage: boolean; sources?: Array<ImageSource> },
    ref: RefObject<HTMLCanvasElement>,
  ) => {
    if (showImage) {
      return (
        <>
          <Image
            src={imageSrc}
            sources={sources}
            width='668'
            height='2637'
            alt='Guilloche'
            isLazy={true}
            sx={{
              width: { xs: '50%', lg: '70%' },
              top: { xs: '30%', sm: '10%', lg: '-15%' },
              left: { xs: 0, lg: '-10%' },
              position: 'absolute',
            }}
          />
        </>
      );
    }

    return <GuillocheCanvas ref={ref} />;
  },
);

const ImageMoment = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <Image
      src={src}
      width='238px'
      height='83px'
      alt={alt}
      isLazy={true}
      sx={{
        width: { xs: '165.6px', md: '238px' },
        height: { xs: '65px', md: '83px' },
        objectFit: 'cover',
        borderRadius: '13px',
        boxShadow:
          '0px 86.756px 24.378px 0px rgba(125, 125, 125, 0.00), 0px 55.209px 22.227px 0px rgba(125, 125, 125, 0.01), 0px 30.831px 18.642px 0px rgba(125, 125, 125, 0.05), 0px 13.623px 13.623px 0px rgba(125, 125, 125, 0.09), 0px 3.585px 7.887px 0px rgba(125, 125, 125, 0.10)',
      }}
    />
  );
};

const Moment1 = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '15vh',
        marginLeft: '4vw',
        ...sx,
      }}
    >
      <ImageMoment
        src='https://images.ctfassets.net/ss5kfr270og3/10W4pmTmGDKRBlPeE7ZLHo/df2a1d7f73f3828bc00aabacb4e43c17/moment1.png?fm=webp&q=70&w=576&h=166'
        alt='moment1'
      />
    </FloatingMoment>
  );
};

const VenmoMoment = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '32vh',
        marginLeft: '10vw',
        ...sx,
      }}
    >
      <NotificationMoment
        icon='https://images.ctfassets.net/ss5kfr270og3/YSwn9nM03H7zyUErHpXsS/dce654f0e07491434ff9d00ca469b7e1/Venmo.png?fm=webp&q=70&w=254&h=254'
        iconAlt='venmo'
        title='for late night 🌮🌮🌮'
        description='Venmo'
        time='2m ago'
      />
    </FloatingMoment>
  );
};

const Moment2 = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '49vh',
        marginLeft: '15vw',
        ...sx,
      }}
    >
      <ImageMoment
        src='https://images.ctfassets.net/ss5kfr270og3/5lMPORJ35CEi9ZkTClfmuC/6c4596a8633c09a71992fe99677459fe/moment2.png?fm=webp&q=100&w=576&h=166'
        alt='moment2'
      />
    </FloatingMoment>
  );
};

const CarvanaMoment = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '66vh',
        marginLeft: '12vw',
        ...sx,
      }}
    >
      <NotificationMoment
        icon='https://images.ctfassets.net/ss5kfr270og3/3dwPjiWbz2EpbdhyDRP8Mn/3b4042e51a07b06031e6b0a9ddb786a9/Carvana.png?fm=webp&q=70&w=254&h=254'
        iconAlt='carvana'
        title="You're pre-qualified!"
        description='Carvana'
        time='1hr ago'
      />
    </FloatingMoment>
  );
};

const Moment3 = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '83vh',
        marginLeft: '8vw',
        ...sx,
      }}
    >
      <ImageMoment
        src='https://images.ctfassets.net/ss5kfr270og3/4ktxRP6n3X4olOZvTY6CQ8/30ec724f3031515c1f3fd1bc0df36776/moment3.png?fm=webp&q=70&w=576&h=166'
        alt='moment3'
      />
    </FloatingMoment>
  );
};

const CitiMoment = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '100vh',
        marginLeft: '12vw',
        ...sx,
      }}
    >
      <NotificationMoment
        icon='https://images.ctfassets.net/ss5kfr270og3/73s1nZJxfHSosVSa3RBiLj/02ee1c52a75be757b7a1fdd3ee13db5d/Banks-US.png?fm=webp&q=70&w=254&h=254'
        iconAlt='citibank'
        title='Checking account linked'
        description='Citi'
        time='7m ago'
      />
    </FloatingMoment>
  );
};

const RobinhoodMoment = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '100vh',
        marginLeft: '12vw',
        ...sx,
      }}
    >
      <NotificationMoment
        icon='https://images.ctfassets.net/ss5kfr270og3/6ffFslxGF555jjvupDmzhv/0e2b5c9f2c1120b324ddae84bf04e34a/Robinhood.png?fm=webp&q=70&w=254&h=254'
        iconAlt='robinhood'
        title='$PLAID order filled'
        description='Robinhood'
        time='Now'
      />
    </FloatingMoment>
  );
};

const Moment4 = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '117vh',
        marginLeft: '2vw',
        ...sx,
      }}
    >
      <ImageMoment
        src='https://images.ctfassets.net/ss5kfr270og3/1uyEDzWCa5DL0QjnmHoZIZ/0979df622ebe4861ce846f228c935878/moment4.png?fm=webp&q=90&w=476&h=166'
        alt='moment4'
      />
    </FloatingMoment>
  );
};

const ChimeMoment = ({ sx }: MomentProps) => {
  return (
    <FloatingMoment
      sx={{
        top: '100vh',
        marginLeft: '12vw',
        ...sx,
      }}
    >
      <NotificationMoment
        icon='https://images.ctfassets.net/ss5kfr270og3/7wxAvraQxeuegyvgIL6w81/15aa5d904e1ef0c9696eeab4ca3289c2/Chime.png?fm=webp&q=70&w=254&h=254'
        iconAlt='chime logo'
        title='Your account is funded'
        description='Chime'
        time='2m ago'
      />
    </FloatingMoment>
  );
};

const MobileMoments = () => {
  const mobileStyles = {
    position: 'relative',
    top: 'unset',
    marginLeft: 'unset',
    display: {
      xs: 'block',
    },
    minHeight: '55px',
  };

  return (
    <Stack
      gap={{
        xs: 5,
        md: 11,
      }}
      sx={{
        marginTop: { xs: '10rem', sm: 0 },
        display: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'none',
        },
        width: '100%',
      }}
    >
      <ReactMarquee
        pauseOnHover={false}
        pauseOnClick={false}
        gradient={false}
        key='logo-marquee-1'
        speed={25}
        style={{
          overflowX: 'unset',
        }}
      >
        <Stack
          direction='row'
          sx={{
            gap: '50px',
            alignItems: 'center',
            ':last-child': {
              marginRight: '50px',
            },
          }}
        >
          <RobinhoodMoment sx={mobileStyles} />
          <Moment1 sx={mobileStyles} />
          <VenmoMoment sx={mobileStyles} />
          <Moment2 sx={mobileStyles} />
          <CitiMoment sx={mobileStyles} />
          <Moment3 sx={mobileStyles} />
          <CarvanaMoment sx={mobileStyles} />
          <Moment4 sx={mobileStyles} />
          <ChimeMoment sx={mobileStyles} />
        </Stack>
      </ReactMarquee>
      <ReactMarquee
        pauseOnHover={false}
        pauseOnClick={false}
        gradient={false}
        key='logo-marquee-2'
        speed={25}
        style={{
          overflowX: 'unset',
        }}
        direction='right'
      >
        <Stack
          gap={6}
          direction='row'
          sx={{
            gap: '50px',
            alignItems: 'center',
            ':last-child': {
              marginRight: '50px',
            },
          }}
        >
          <CitiMoment sx={mobileStyles} />
          <Moment2 sx={mobileStyles} />
          <VenmoMoment sx={mobileStyles} />
          <Moment1 sx={mobileStyles} />
          <RobinhoodMoment sx={mobileStyles} />
          <ChimeMoment sx={mobileStyles} />
          <Moment4 sx={mobileStyles} />
          <CarvanaMoment sx={mobileStyles} />
          <Moment3 sx={mobileStyles} />
        </Stack>
      </ReactMarquee>

      <ReactMarquee
        pauseOnHover={false}
        pauseOnClick={false}
        gradient={false}
        key='logo-marquee-3'
        speed={25}
        style={{
          overflowX: 'unset',
        }}
      >
        <Stack
          gap={6}
          direction='row'
          sx={{
            gap: '50px',
            alignItems: 'center',
            ':last-child': {
              marginRight: '50px',
            },
          }}
        >
          <CarvanaMoment sx={mobileStyles} />
          <Moment4 sx={mobileStyles} />
          <ChimeMoment sx={mobileStyles} />
          <RobinhoodMoment sx={mobileStyles} />
          <Moment1 sx={mobileStyles} />
          <VenmoMoment sx={mobileStyles} />
          <Moment2 sx={mobileStyles} />
          <CitiMoment sx={mobileStyles} />
          <Moment3 sx={mobileStyles} />
        </Stack>
      </ReactMarquee>
    </Stack>
  );
};

const StatRow = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Row
      columns={{
        xs: 19,
        lg: 10,
      }}
      sx={sx}
    >
      {children}
    </Row>
  );
};

const NetworkSection = (props) => {
  const guillocheRef = React.useRef<HTMLCanvasElement>(null);
  const showGuillocheImage = useShowGuillocheImage();

  useWorkerGuilloche({
    canvasRef: guillocheRef,
    svgPaths: props.svgDetails.ribbon2.paths,
    svgWidth: props.svgDetails.ribbon2.width,
    svgHeight: props.svgDetails.ribbon2.height,
    intensity: 1,
    speed: 0.27,
    horizontalWave: false,
    verticalWave: true,
    zAxisWave: true,
    id: 'network-canvas',
    scale: { xs: 1, sm: 2, xxl: 2 },
    x: { xs: -45, sm: -50, xxl: -30 },
    y: { sm: 0, md: 10 },
  });

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 100,
          top: { xs: '-23vw', sm: '-10vw', lg: '-5vw' },
          width: '100%',
        }}
      >
        <Image
          src='https://images.ctfassets.net/ss5kfr270og3/7Ljx2exCYHkJmhv3y8ZjTU/39a8cbe2d4a042317140513906aeef4d/solutionpilecorners.png?fm=webp&q=30&w=3456&h=510'
          sources={[
            {
              media: '(max-width: 639px)',
              srcSet:
                'https://images.ctfassets.net/ss5kfr270og3/73i6CoVrKxT2d1Wow4Mh7y/ffae1f078809b4fc499a8498697a8f21/Faux-Corner-Mobile.png?fm=webp&q=30&w=750&h=402',
            },
            {
              media: '(min-width: 640px) and (max-width: 1279px)',
              srcSet:
                'https://images.ctfassets.net/ss5kfr270og3/3SAuyKWyVGGbmjnBnBUnIO/d6345b1a683b2e042d57a887709df63f/solutionpilecorners-tablet.png?fm=webp&q=30&w=1440',
            },
          ]}
          width='1728'
          height='255'
          alt='sectionborder'
          isLazy={true}
          sx={{
            width: '100%',
          }}
        />
      </Box>
      <Row
        sx={{
          height: {
            xs: '1000px',
            sm: '1500px',
            lg: '1800px',
            xl: '2000px',
          },
          paddingBottom: {
            xs: '24px',
            lg: 0,
          },
          minHeight: 'fit-content',
          display: 'flex',
          zIndex: 1,
          position: 'relative',
          overflowX: 'clip',
          overflowY: 'visible',
          margin: 0,
        }}
      >
        <Column
          sx={{
            height: '100%',
            position: {
              xs: 'absolute',
              lg: 'relative',
            },
            zIndex: 0,
          }}
          xs={24}
          sm={24}
          lg={12}
        >
          <Guilloche
            showImage={showGuillocheImage}
            imageSrc='https://images.ctfassets.net/ss5kfr270og3/0uwFAABQtXe7eQ11poff6/b3e5af4e21ac809213d2a2505c652908/NetworkGuilloche.png?fm=webp&q=50'
            sources={[
              {
                media: '(max-width: 639px)',
                srcSet:
                  'https://images.ctfassets.net/ss5kfr270og3/0uwFAABQtXe7eQ11poff6/b3e5af4e21ac809213d2a2505c652908/NetworkGuilloche.png?fm=webp&q=25',
              },
            ]}
            ref={guillocheRef}
          />
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              display: 'inline-block',
            }}
          >
            <Moment1 />
            <VenmoMoment />
            <Moment2 />
            <CarvanaMoment />
            <Moment3 />
            <RobinhoodMoment />
            <Moment4 />
          </Box>
        </Column>
        <Column
          xsOffset={2}
          xs={19}
          lgOffset={0}
          lg={10}
          sx={{
            zIndex: 1,
            paddingTop: {
              xs: '130px',
              sm: '100px',
              lg: '180px',
              xl: '250px',
            },
            overflow: 'hidden',
          }}
        >
          <Heading2>A network that gets more powerful daily</Heading2>
          <Typography
            variant='p'
            sx={{
              textAlign: 'left',
              marginTop: '24px',
              fontSize: {
                xs: '1.8rem',
                sm: '2rem',
                md: '2.4rem',
              },
              paddingRight: {
                lg: '10rem',
              },
            }}
          >
            When you build on the world’s largest open banking data network,
            every product and solution is made better for you and your customers
            through new data and insights. Your services improve each day
            without you having to do a thing.
          </Typography>
          <StatRow
            sx={{
              marginTop: {
                xs: '6.4rem',
                sm: '9.5rem',
                lg: '14rem',
              },
            }}
          >
            <Column xs={8} lg={4}>
              <Number>1 in 2</Number>

              <Heading3>adults in the U.S.</Heading3>
              <Paragraph>
                Offer a trusted experience used by half of U.S. adults
              </Paragraph>
            </Column>
            <Column xsOffset={3} xs={8} lgOffset={1} lg={4}>
              <CountingNumber number={25} suffix='%' speedMs={40} />
              <Heading3>higher conversion</Heading3>
              <Paragraph>
                Increase sign-ups by up to 25% with easy onboarding
              </Paragraph>
            </Column>
          </StatRow>
          <StatRow
            sx={{
              marginTop: {
                xs: '5rem',
                sm: '7rem',
              },
              marginBottom: {
                xs: '30px',
                sm: '130px',
                lg: '140px',
              },
            }}
          >
            <Column xs={8} lg={4}>
              <CountingNumber number={500} suffix='k+' speedMs={40} />
              <Heading3>daily connections</Heading3>
              <Paragraph>
                Get data from thousands of new daily connections
              </Paragraph>
            </Column>
            <Column xsOffset={3} xs={8} lgOffset={1} lg={4}>
              <CountingNumber number={20} speedMs={40} />
              <Heading3>countries</Heading3>
              <Paragraph>
                Scale adoption and grow into new global markets
              </Paragraph>
            </Column>
          </StatRow>
        </Column>
        <MobileMoments />
      </Row>
    </Box>
  );
};

export default NetworkSection;
