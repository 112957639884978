import useIsChromium from 'src/hooks/useIsChromium';
import { StickyCardChildrenProps } from './StickyCard';
import React from 'react';
import { getExitTransform } from './StickyCardContents';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import Box from 'threads5/Box';
import useIsPotato from 'src/hooks/useIsPotato';
import { Image } from 'src/components-v2/Media';
import useShowGuillocheImage from 'src/hooks/useShowGuillocheImage';

interface Props extends StickyCardChildrenProps {
  guillocheScaleMultiplier: number;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  sx?: SxProps<Theme>;
  imageSrc?: string;
  imageSx?: SxProps<Theme>;
}

const CardGuilloche: React.FC<Props> = ({
  sx,
  guillocheScaleMultiplier,
  canvasRef,
  pixelsScrolledPastInitialTop,
  imageSrc,
  imageSx,
}) => {
  const isPotato = useIsPotato();
  const isChromium = useIsChromium();
  const showImage = useShowGuillocheImage();
  const translateY = isPotato
    ? 0
    : getExitTransform(pixelsScrolledPastInitialTop).translateY;

  if (showImage) {
    return (
      <Box
        sx={{
          position: 'absolute',
          width: { xs: `100%`, sm: '250%', md: '200%', lg: '100%' },
          height: '100%',
          display: isPotato ? 'block' : 'none',
          ...imageSx,
        }}
      >
        <Image
          width={400}
          height={400}
          src={imageSrc}
          alt='Guilloche'
          sx={{
            width: '100%',
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        transform: isChromium
          ? `translateY(0)`
          : `translate3d(0px, ${-(
              translateY / guillocheScaleMultiplier
            )}px, 0px)`,
        transition: !isChromium && 'transform 0.4',
        width: `${100 * guillocheScaleMultiplier}%`,
        opacity: isPotato ? 0 : 1,
        ...sx,
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          background: 'transparent',
        }}
      />
    </Box>
  );
};

export default CardGuilloche;
