import React from 'react';
import { Box } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import useIsPotato from 'src/hooks/useIsPotato';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const CardPile = ({ children, sx }: Props) => {
  const isPotato = useIsPotato();
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      sx={{
        timelineScope: `${React.Children.map(children, (child, index) => {
          return `--solution-card-${index}`;
        }).join(',')}`,
        marginTop: isPotato && '-40px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default CardPile;
