import React from 'react';
import styled from 'threads5/styles/styled';

import { useToggleContext } from './Toggle';
import { useCriticalRenderContext } from 'src/hooks/useCriticalRenderContext';
import { Image } from 'src/components-v2/Media';

export const desktopAssets = {
  openEyes:
    'https://images.ctfassets.net/ss5kfr270og3/2JblDwCyPDIbjPx20xxK5I/c2e338a2a7bc1010adc034d8eb3f8999/besk-desktop-open.png?fm=webp&q=30',
  closedEyes:
    'https://images.ctfassets.net/ss5kfr270og3/2oJaDLy1lkPa0151ROC2EX/112bd81002fbba76fae1435de2100d83/ben-desktop-closed.png?fm=webp&q=30',
  gradient:
    'https://images.ctfassets.net/ss5kfr270og3/3BWkpdMIGOnAtu1ev1erww/bfc7fe3d2fcdc364ace3dc3d4c062f39/Tail_Holo_Strip__1_.png?fm=webp&q=30',
};

export const mobileAssets = {
  openEyes:
    'https://images.ctfassets.net/ss5kfr270og3/6OwFElmmvoLtcJhrsfKZWn/8ea92c2db103275a49101c4fd4d1e86c/ben-mobile-open.png?fm=webp&q=30&w=676',
  closedEyes:
    'https://images.ctfassets.net/ss5kfr270og3/6ncL1tF0dPjYJwnfeLd5Qq/4cbf39c167414ac176e31b70d9ecd019/ben-mobile-closed.png?fm=webp&q=30&w=676',
};

export const assets = {
  desktop: desktopAssets,
  mobile: mobileAssets,
};

const BenImg = styled(Image)<{ haveAllCriticalAssetsLoaded?: boolean }>(
  ({ theme, haveAllCriticalAssetsLoaded }) => {
    return {
      opacity: haveAllCriticalAssetsLoaded ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
      position: 'absolute',
      [theme.breakpoints.up('xs')]: {
        bottom: '102px',
        right: '-154px',
        width: '338px',
        height: '271px',
      },
      [theme.breakpoints.up('sm')]: {
        bottom: 0,
        right: '-400px',
        width: '844px',
        height: '650px',
      },
      [theme.breakpoints.up('md')]: {
        bottom: 0,
        right: '-390px',
        width: '844px',
        height: '650px',
      },
      [theme.breakpoints.up('lg')]: {
        bottom: 0,
        right: '-182px',
        width: '944px',
        height: '736px',
      },
      [theme.breakpoints.up('xl')]: {
        bottom: 0,
        right: 0,
        width: '944px',
        height: '736px',
      },
      maxWidth: 'unset',
      zIndex: 1,
    };
  },
);

const GradientImg = styled('img')<{ haveAllCriticalAssetsLoaded?: boolean }>(
  ({ theme, haveAllCriticalAssetsLoaded }) => {
    return {
      opacity: haveAllCriticalAssetsLoaded ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
      position: 'absolute',
      maxWidth: 'unset',
      zIndex: 1,
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        bottom: 0,
        width: '944px',
        height: '736px',
      },
      [theme.breakpoints.up('xl')]: {
        display: 'block',
        right: '-943px',
      },
    };
  },
);

const Ben: React.FC = () => {
  const { assetsLoaded } = useCriticalRenderContext();
  const { isWinking } = useToggleContext();

  return (
    <>
      <BenImg
        className='critical-asset'
        isLazy={false}
        src={assets.desktop[isWinking ? 'closedEyes' : 'openEyes']}
        key={isWinking ? 'wink' : 'full_eyes'}
        alt='benjamin franklin'
        width='944'
        height='736'
        haveAllCriticalAssetsLoaded={assetsLoaded}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet: assets.mobile[isWinking ? 'closedEyes' : 'openEyes'],
          },
          {
            media: '(min-width: 640px)',
            srcSet: assets.desktop[isWinking ? 'closedEyes' : 'openEyes'],
          },
        ]}
      />
      <GradientImg
        // className='critical-asset' // removing this to try and reduce # of images that need to be loaded
        src={assets.desktop.gradient}
        alt='rainbow gradient'
        width='944'
        height='736'
        haveAllCriticalAssetsLoaded={assetsLoaded}
      />
    </>
  );
};

export default Ben;
