import React, { createContext, useContext, useState } from 'react';
import styled from 'threads5/styles/styled';
import { Stack } from 'src/components-v2/Layout';
import Theme from 'threads5/themes/theme';
import SxProps from 'threads5/themes/sx-props';
import { useCriticalRenderContext } from 'src/hooks/useCriticalRenderContext';
import useMeasure from 'react-use-measure';
import useHasMouse from 'src/hooks/useHasMouse';
import useIsPotato from 'src/hooks/useIsPotato';

const forbiddenProps = new Set(['variant', 'sx', 'fontSize']);
const shouldForwardProp = (prop: string) => {
  return !forbiddenProps.has(prop);
};

const Btn = styled('a', {
  shouldForwardProp,
})<IButton>(({ theme, variant = 'primary' }) => {
  const variantStyles = {
    primary: {
      background:
        'linear-gradient(108deg, #CDF5FD 0%, #D8FEF3 50%, #E8FED7 100%)',
      color: theme.palette['base/turquoise/800'],
      ':hover, :focus': {
        background: 'linear-gradient(266deg, #C0FFC0 -7.84%, #FFF 100%)',
      },
    },
    secondary: {
      background: '#02294B',
      color: theme.palette['base/black/100'],
      ':hover, :focus': {
        color: '#02294B',
        background:
          'linear-gradient(272deg, #B5EEDE 0%, #D3E4F8 33%, #E3DFFF 66%, #FAD7FF 100%)',
      },
    },
  };

  return {
    borderRadius: '46px',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    fontFamily: theme.typography.sans,
    fontSize: '2.0rem',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.6px',
    lineHeight: '150%',
    padding: '10px 32px',
    textAlign: 'center',
    ...variantStyles[variant],
    [theme.breakpoints.down('md')]: {
      letterSpacing: '-0.54px',
      padding: '8px 20px',
    },
  };
});

interface IButton {
  variant?: 'primary' | 'secondary';
}

const ToggleContext = createContext<
  | {
      isWinking: boolean;
      setIsWinking: React.Dispatch<React.SetStateAction<boolean>>;
      isPsychedelic: boolean;
      setIsPsychedelic: React.Dispatch<React.SetStateAction<boolean>>;
      isToggled: boolean;
      setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const ToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [isWinking, setIsWinking] = useState(false);
  const [isPsychedelic, setIsPsychedelic] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const isPotato = useIsPotato();
  const hasMouse = useHasMouse();

  const handlePsychedelic = (isClicked: boolean) => {
    if (isPotato || !hasMouse) {
      return;
    }
    setIsPsychedelic(isClicked);
  };

  const handleWinking = (isClicked: boolean) => {
    if (isPotato || !hasMouse) {
      return;
    }
    setIsWinking(isClicked);
  };

  return (
    <ToggleContext.Provider
      value={{
        isWinking,
        setIsWinking: handleWinking,
        isPsychedelic,
        setIsPsychedelic: handlePsychedelic,
        isToggled,
        setIsToggled,
      }}
    >
      {children}
    </ToggleContext.Provider>
  );
};

export const useToggleContext = () => {
  const context = useContext(ToggleContext);
  if (context) {
    return context;
  }
  return {
    isWinking: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsWinking: () => {},
    isPsychedelic: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsPsychedelic: () => {},
    isToggled: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsToggled: () => {},
  };
};

const ToggleStack = styled(Stack)(({ theme }) => {
  return {
    zIndex: 2,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    alignItems: 'center',
    '&.loaded': {
      opacity: 1,
    },
  };
});

const StyledBtn = styled(Btn)({});

const SecondaryBtn = styled(StyledBtn)<{ width?: number }>(({ width }) => {
  return {
    width: width ? `${width}px` : 'auto',
  };
});

const Toggle = ({ sx }: { sx?: SxProps<Theme> }): React.ReactElement => {
  const { setIsWinking, setIsPsychedelic, setIsToggled } = useToggleContext();
  const { isPlaidSansLoaded } = useCriticalRenderContext();
  const [primaryRef, { width: primaryWidth }] = useMeasure();
  const hasMouse = useHasMouse();

  const doPsychedelic = (isClicked: boolean) => {
    setIsToggled(isClicked);

    // Psychedelic mode is a bit buggy on mobile,
    // so only allowing it on non-mobile sizes
    if (hasMouse) {
      setIsPsychedelic(isClicked);
    }
  };

  return (
    <ToggleStack
      gap={{ xs: 1, sm: 2 }}
      direction='row'
      className={isPlaidSansLoaded ? 'loaded' : ''}
      sx={sx}
      justifyContent={{ xs: 'center', sm: 'flex-start' }}
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
    >
      <StyledBtn
        href='#contactForm'
        variant='primary'
        ref={primaryRef}
        onMouseOver={() => {
          return setIsWinking(true);
        }}
        onFocus={() => {
          return setIsWinking(true);
        }}
        onMouseLeave={() => {
          return setIsWinking(false);
        }}
        onBlur={() => {
          return setIsWinking(false);
        }}
        sx={{
          fontSize: { xs: '1.6rem', md: '1.8rem', lg: '2.0rem' },
        }}
      >
        Talk with our team
      </StyledBtn>
      <SecondaryBtn
        href='https://dashboard.plaid.com/signup'
        variant='secondary'
        onMouseOver={() => {
          return doPsychedelic(true);
        }}
        onFocus={() => {
          return doPsychedelic(true);
        }}
        onMouseLeave={() => {
          return doPsychedelic(false);
        }}
        onBlur={() => {
          return doPsychedelic(false);
        }}
        width={primaryWidth}
        sx={{
          fontSize: { xs: '1.6rem', md: '1.8rem', lg: '2.0rem' },
        }}
      >
        Start building
      </SecondaryBtn>
    </ToggleStack>
  );
};

export default Toggle;
