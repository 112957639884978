import styled from 'threads5/styles/styled';
import React, { forwardRef, RefObject } from 'react';
import { Image } from 'src/components-v2/Media';
import useWorkerGuilloche from 'src/hooks/useWorkerGuilloche';
import Box from 'threads5/Box';
import Stack from 'threads5/Stack';
import CodeBlock from './CodeBlock';
import GlowingCard from '../SolutionCards/GlowingCard';
import { Column, Container, Row } from 'src/components-v2/Layout';
import { Button as ButtonLink } from 'src/components-v3/Homepage/Buttons/Button';
import BtnPrimary from 'src/components-v2/Inputs/Button/BtnPrimary';
import useShowGuillocheImage from 'src/hooks/useShowGuillocheImage';
import useIsPotato from 'src/hooks/useIsPotato';

const Heading2 = styled('h2')(({ theme }) => {
  return {
    fontFamily: theme.typography.plaidSans,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '7rem',
    lineHeight: '113%',
    letterSpacing: '-2.8px',
    background:
      'var(--Code-Gradient-Fill, linear-gradient(272deg, #59D1FB 1.15%, #8B8CF9 52.54%, #D5BBFF 88.94%))',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    [theme.breakpoints.down('xl')]: {
      fontSize: '6.7rem',
      letterSpacing: '-1.6px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.8rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.6rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '3rem',
    },
  };
});

const Description = styled('p')(({ theme }) => {
  return {
    marginBottom: 0,
    textAlign: 'center',
    fontSize: '2.4rem',
    lineHeight: '150%' /* 72.8px */,
    color: '#fff',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.2rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.6rem',
    },
  };
});

const CodeBlockGrid: React.FC = ({ children }) => {
  return (
    <Row
      sx={{
        width: '100%',
      }}
      columns={22}
    >
      <Column xsOffset={2} xs={18} mdOffset={3} md={16} lgOffset={3} lg={16}>
        {children}
      </Column>
    </Row>
  );
};

const Guilloche = forwardRef(({}, ref: RefObject<HTMLCanvasElement>) => {
  const showImage = useShowGuillocheImage();

  if (showImage) {
    return (
      <Image
        src='https://images.ctfassets.net/ss5kfr270og3/4vyk37haCFiMwSbDMcpM62/8baae951e42258af9b315ef7c6baef6e/Group_1321321426.png'
        width={400}
        height={400}
        alt='Guilloche'
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: {
          xs: '-150px',
          sm: '-150px',
          md: '-200px',
          lg: '-220px',
        },
        width: {
          xs: '250%',
          sm: '200%',
          md: '150%',
          lg: '100%',
        },
        zIndex: -2,
      }}
    >
      <canvas
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          background: 'transparent',
        }}
      />
    </Box>
  );
});

const APISection = (props) => {
  const isPotato = useIsPotato();
  const canvas5 = React.useRef<HTMLCanvasElement>(null);
  useWorkerGuilloche({
    canvasRef: canvas5,
    svgPaths: props.svgDetails.dune.paths,
    svgWidth: props.svgDetails.dune.width,
    svgHeight: props.svgDetails.dune.height,
    intensity: 0.2,
    speed: 0.5,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    id: 'developer-canvas',
    scale: { xl: 1, xxl: 1.5 },
    y: { sm: -15, md: -15, xl: -11, xxl: -5 },
  });

  return (
    <Container
      sx={{
        maxWidth: 'unset',
      }}
    >
      <Row
        sx={{
          height: {
            xs: '71rem',
            sm: '76rem',
            md: '78rem',
            lg: '85rem',
          },
          paddingTop: {
            xs: '6rem',
            lg: 0,
          },
          marginTop: {
            lg: '-250px',
            xl: '-400px',
          },
          position: 'relative',
          zIndex: 1,
          minWidth: {
            lg: '440px',
          },
        }}
      >
        <Column xsOffset={1} xs={22}>
          <GlowingCard
            sx={{
              height: {
                xs: '64rem',
                sm: '65rem',
                md: '73rem',
                lg: '80rem',
              },
              maxHeight: '88rem',
              background: '#0D0D3F',
              alignItems: 'flex-end',
            }}
            containerSx={{
              height: {
                xs: '64rem',
                sm: '65rem',
                md: '73rem',
                lg: '80rem',
              },
              maxHeight: '88rem',
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                height: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                gap: {
                  xs: '3.6rem',
                  sm: '4rem',
                  // xl: '4rem',
                },
                paddingTop: {
                  xs: '4rem',
                  sm: '5rem',
                  md: '6rem',
                  lg: '7rem',
                },
              }}
            >
              <Stack
                sx={{
                  alignItems: 'center',
                  flexDirection: 'column',
                  // marginTop: '11.5vh',
                  gap: {
                    xs: '2rem',
                    sm: '2.7rem',
                    // md: '3.4rem',
                    // lg: '4.5rem',
                    // xl: '2.7rem',
                  },
                }}
              >
                <CodeBlockGrid>
                  <Heading2>
                    Want access to 12K+ banks?
                    <br />
                    We’ve got the keys.
                  </Heading2>
                </CodeBlockGrid>

                <CodeBlockGrid>
                  <Description>
                    Spend your time dreaming about the experiences you’ll
                    create, not stressing over how to get the data. Our APIs can
                    unlock it all.
                  </Description>
                </CodeBlockGrid>
                <Stack direction={{ sm: 'row' }} flexWrap='wrap' gap='29px'>
                  <ButtonLink
                    variant='elizabeth'
                    href='https://dashboard.plaid.com/signup'
                  >
                    Get the API keys
                  </ButtonLink>
                  <BtnPrimary
                    href='/docs'
                    sx={{
                      justifyContent: 'center',
                    }}
                  >
                    Read the docs
                  </BtnPrimary>
                </Stack>
              </Stack>

              <CodeBlockGrid>
                <Box
                  sx={{
                    position: 'relative',
                    '&:hover .codeGlow': {
                      transform: 'scale(1.08)',
                    },
                  }}
                >
                  {!isPotato && (
                    <Box
                      className='codeGlow'
                      sx={{
                        position: 'absolute',
                        top: 0,
                        height: '100%',
                        width: '100%',
                        // maxHeight: '47vh',
                        filter: 'blur(40px)',
                        zIndex: -1,
                        overflow: 'hidden',
                        transform: 'scale(1.02)',
                        transition: 'transform 0.5s',
                      }}
                    >
                      <Image
                        className='codeGlowImage'
                        src='https://images.ctfassets.net/ss5kfr270og3/7q1mJPw6bmeeUruloUUcxa/d69826c7922a24d66660ccd4e732a79a/3621384b921832f85bca12c7d2a4f5da?fm=webp&q=1'
                        alt='glow'
                        width='374'
                        height='400'
                        sx={{
                          height: '200vw',
                          width: '200vw',
                          left: {
                            xs: '-65vw',
                            md: '-75vw',
                          },
                          top: {
                            xs: '-100vh',
                            md: '-50vh',
                          },
                          position: 'absolute',
                          maxWidth: '200vw',
                          minHeight: '2000px',
                          '@keyframes rotate': {
                            '0%': {
                              transform: 'rotate(0deg)',
                            },
                            '100%': {
                              transform: 'rotate(360deg)',
                            },
                          },
                          transition: 'transform 0.5s',
                          animation: 'rotate 15s linear infinite',
                        }}
                      />
                    </Box>
                  )}
                  <CodeBlock />
                </Box>
              </CodeBlockGrid>
            </Box>

            <Guilloche ref={canvas5} />
          </GlowingCard>
        </Column>
      </Row>
    </Container>
  );
};

export default APISection;
