import React from 'react';
import fs from 'fs';
import path from 'path';

import { MainTemplate } from 'src/templates';
import { Locales } from 'src/lib/constants';
import Contact from 'src/components-v3/Homepage/Contact/Contact';
import Hero from 'src/components-v3/Homepage/Hero/Hero';
import APISection from 'src/components-v3/Homepage/APISection/APISection';
import SolutionCards from 'src/components-v3/Homepage/SolutionCards/SolutionCards';
import NetworkSection from 'src/components-v3/Homepage/Network/Network';
import { ToggleProvider } from 'src/components-v3/Homepage/Hero/Toggle';
import { assets } from 'src/components-v3/Homepage/Hero/Ben';
import { CriticalRenderProvider } from 'src/hooks/useCriticalRenderContext';
import Portal from 'src/components-v3/Homepage/Portal/Portal';
import Carousel from 'src/components-v3/Homepage/Carousel/Carousel';
import Spacer from 'src/components-v2/Layout/Spacer/Spacer';
import Footer from 'src/components-v3/Footer';

const metaData = {
  'meta-title': 'Plaid: Enabling all companies to build fintech solutions',
  'meta-description':
    'Plaid helps all companies build fintech solutions by making it easy, safe and reliable for people to connect their financial data to apps and services.',
  hasForeignContentNotification: true,
  hasFooter: true,
  hasNavigation: true,
  noIndex: false,
  preloadImages: [
    {
      src: assets.desktop.openEyes,
      type: 'image/webp',
      media: '(min-width: 640px)',
    },
    {
      src: assets.mobile.openEyes,
      type: 'image/webp',
      media: '(max-width: 639px)',
    },
    { src: assets.desktop.gradient, type: 'image/webp' },
    {
      src: assets.desktop.closedEyes,
      type: 'image/webp',
      media: '(min-width: 640px)',
    },
    {
      src: assets.mobile.closedEyes,
      type: 'image/webp',
      media: '(max-width: 639px)',
    },
  ],
};

const Guilloche = (props) => {
  return (
    <MainTemplate
      {...metaData}
      locale={Locales.EN_US}
      cmsTheme='web 3.0'
      sx={{
        '&#main-content': {
          overflowX: 'unset',
          overflowY: 'unset',
          background:
            'linear-gradient(180deg, #F0F2FD 0%, #FAF7FF 35.5%, #FAF7FF 47%, #FAF7FF 68.5%, #EAEDFC 99.5%)',
        },
      }}
      hasFooter={false}
    >
      <CriticalRenderProvider value={{ assetQuerySelector: '.critical-asset' }}>
        <ToggleProvider>
          <Hero {...props} />
        </ToggleProvider>
      </CriticalRenderProvider>
      <SolutionCards {...props} />
      <NetworkSection {...props} />
      <APISection {...props} />
      <Carousel {...props} />
      <ToggleProvider>
        <Contact {...props}>
          <>
            <Spacer height={80} />
            <Portal />
          </>
        </Contact>
      </ToggleProvider>
      <Footer roundedTop overlap />
    </MainTemplate>
  );
};

export async function getStaticProps() {
  const svgFiles = [
    path.resolve(process.cwd(), 'public', 'assets/img/guilloche/dune.svg'),
    path.resolve(process.cwd(), 'public', 'assets/img/guilloche/ribbon.svg'),
    path.resolve(process.cwd(), 'public', 'assets/img/guilloche/ribbon2.svg'),
    path.resolve(
      process.cwd(),
      'public',
      'assets/img/guilloche/Waves_2560@2x.svg',
    ),
    path.resolve(process.cwd(), 'public', 'assets/img/guilloche/dune2.svg'),
  ];

  const svgDetails = [];

  svgFiles.forEach((svgPath) => {
    const svgContent = fs.readFileSync(svgPath, 'utf-8');
    const dimensionMatch = svgContent.match(
      /<svg[^>]*\swidth="([^"]*)"\s*height="([^"]*)"/,
    );
    const width = dimensionMatch ? parseFloat(dimensionMatch[1]) : null;
    const height = dimensionMatch ? parseFloat(dimensionMatch[2]) : null;
    const pathRegex = /<path\s+[^>]*d="([^"]*)"/g;
    const paths = [];
    let match;
    while ((match = pathRegex.exec(svgContent)) !== null) {
      paths.push(match[1]);
    }
    svgDetails.push({ paths, width, height, filename: path.basename(svgPath) });
  });

  return {
    props: {
      svgDetails: {
        dune: svgDetails[0],
        dune2: svgDetails[4],
        ribbon: svgDetails[1],
        ribbon2: svgDetails[2],
        fingerprint: svgDetails[3],
      },
    },
  };
}

export default Guilloche;
