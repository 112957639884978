import React from 'react';
import styled from 'threads5/styles/styled';
import { StickyCardChildrenProps } from './StickyCard';
import { Box } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import ProductShot from './ProductShot';
import useIsChromium from 'src/hooks/useIsChromium';
import useIsPotato from 'src/hooks/useIsPotato';
import {
  Button as ButtonLink,
  IButtonProps,
} from 'src/components-v3/Homepage/Buttons/Button';

interface Props extends StickyCardChildrenProps {
  assetSrc: string;
  disableAssetGlow?: boolean;
  heading: string;
  subheading: string;
  buttonText: string;
  href?: string;
  sx?: SxProps<Theme>;
  headingSx?: SxProps<Theme>;
  subheadingSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  productShotSx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
  glowSx?: SxProps<Theme>;
  disableReverse?: boolean;
  disableExponentialDecay?: boolean;
  buttonHref: string;
  buttonVariant: IButtonProps['variant'];
}

const Heading2 = styled('h2')(({ theme }) => {
  return {
    color: '#FFF',
    fontSize: '5rem',
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: '-2px',
    marginBottom: '3.2rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.2rem',
      letterSpacing: '-1px',
    },
    fontFamily: theme.typography.plaidSans,
  };
});

const Subheading = styled('p')(({ theme }) => {
  return {
    color: '#FFF',
    fontSize: '2.4rem',
    lineHeight: '132%',
    marginBottom: '4.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.2rem',
      marginBottom: '4rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.8rem',
      lineHeight: '150%',
    },
  };
});

export const getExitTransform = (
  pixelsScrolledPastInitialTop: null | number,
  disableExponentialDecay = false,
  isPotato = false,
) => {
  let translateY = 0;
  const opacity = 1;

  if (isPotato) {
    return {
      translateY,
      opacity,
    };
  }

  if (typeof window !== 'undefined' && pixelsScrolledPastInitialTop > 0) {
    const windowHeight = window.innerHeight;
    const threshold = 0.25 * windowHeight;
    const diff = pixelsScrolledPastInitialTop - threshold;
    if (pixelsScrolledPastInitialTop < threshold) {
      if (!disableExponentialDecay) {
        translateY = pixelsScrolledPastInitialTop;
      }
    } else {
      if (!disableExponentialDecay) {
        translateY =
          threshold +
          (0.1 *
            diff *
            Math.exp(
              Math.max(1, pixelsScrolledPastInitialTop / windowHeight),
            )) /
            Math.exp(1);
      }
    }
  }

  return {
    translateY,
    opacity,
  };
};

const StickyCardContents: React.FC<Props> = ({
  assetSrc,
  heading,
  subheading,
  href = '#',
  buttonText,
  headingSx,
  subheadingSx,
  buttonSx,
  productShotSx,
  imgSx,
  glowSx,
  sx,
  getScaledValue,
  canReverse,
  disableReverse,
  percentElementInView,
  disableAssetGlow,
  pixelsScrolledPastInitialTop,
  disableExponentialDecay,
  index,
  isLastCard,
  buttonHref,
  buttonVariant,
}) => {
  const isPotato = useIsPotato();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const isChromium = useIsChromium();
  const { translateY } = getExitTransform(
    pixelsScrolledPastInitialTop,
    disableExponentialDecay,
    isPotato,
  );
  let containerAnimation = {};

  if (!isChromium) {
    containerAnimation = {
      transform:
        pixelsScrolledPastInitialTop > 0
          ? `translateY(${isLastCard ? 0 : -translateY}px)`
          : 'translateY(0)',
      transition: 'transform 0.4s',
    };
  }

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        bottom: {
          sm: '20vh',
          md: '23vh',
          lg: '15vh',
        },
        top: {
          xs: '-38vh',
          sm: 'unset',
        },
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        transition: 'transform 0.4s, opacity 0.4s',
        opacity: 1,
        ...containerAnimation,
        ...sx,
      }}
      ref={contentRef}
    >
      <ProductShot
        src={assetSrc}
        alt='iphone'
        width={374}
        height={800}
        sx={{
          scale: {
            lg: 1,
          },
          height: {
            xs: '85vh',
            sm: '100vh',
          },
          maxWidth: {
            xs: '200%',
            md: '100%',
          },
          marginBottom: {
            xs: '3rem',
            sm: '3rem',
            md: '4rem',
            lg: '4rem',
          },
          ...productShotSx,
        }}
        getScaledValue={getScaledValue}
        canReverse={canReverse}
        disableReverse={disableReverse}
        percentElementInView={percentElementInView}
        disableGlow={disableAssetGlow}
        pixelsScrolledPastInitialTop={pixelsScrolledPastInitialTop}
        index={index}
        isLastCard={isLastCard}
        imgSx={imgSx}
        glowSx={glowSx}
      />
      <Heading2
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          opacity: 1,
          transition: canReverse
            ? 'transform 0.5s, opacity 0.5s'
            : 'transform 1s, opacity 1s',
          paddingX: {
            xs: '3rem',
          },
          ...headingSx,
        }}
      >
        {heading}
      </Heading2>
      <Subheading
        sx={{
          textAlign: 'center',
          width: '540px',
          maxWidth: '100%',
          top: 0,
          transition: canReverse
            ? 'transform 0.5s, opacity 0.5s'
            : 'transform 1s, opacity 1s',
          opacity: 1,
          paddingX: {
            xs: '3rem',
            sm: '0',
          },
          ...subheadingSx,
        }}
      >
        {subheading}
      </Subheading>
      {/* TODO: this should be a hyperlink */}
      <ButtonLink
        href={buttonHref}
        variant={buttonVariant}
        sx={{
          transition: canReverse
            ? 'transform 0.5s, opacity 0.5s'
            : 'transform 1s, opacity 1s',
          opacity: 1,
          ...buttonSx,
        }}
      >
        {buttonText}
      </ButtonLink>
    </Box>
  );
};

export default StickyCardContents;
