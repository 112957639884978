import { useRef, useState } from 'react';
import useIsPotato from './useIsPotato';

const useVisibilityScale = (
  scrollOffset = 0,
  minVisibility = 0,
  // shouldBeMounted,
) => {
  const isPotato = useIsPotato();
  const ref = useRef<HTMLDivElement>();
  const [percentElementInView] = useState(0);
  const [initialTop] = useState<number | null>(null);
  const [scrollY] = useState(0);
  const actualScrollY = scrollY - scrollOffset;
  const canReverse = actualScrollY > initialTop;
  const pixelsScrolledPastInitialTop =
    initialTop != null ? Math.max(0, actualScrollY - initialTop) : null;

  // const handleScroll = useCallback(() => {
  //   // if (!shouldBeMounted) {
  //   //   return;
  //   // }
  //   let newPercentElementInView =
  //     100 -
  //     (Math.min(ref.current?.offsetTop - window.scrollY, window.innerHeight) /
  //       window.innerHeight) *
  //       100;
  //   if (newPercentElementInView >= 97) {
  //     newPercentElementInView = 100;
  //   }
  //   if (initialTop == null && ref.current?.offsetTop > window.scrollY) {
  //     setInitialTop(ref.current?.offsetTop);
  //   }

  //   setPercentElementInView(newPercentElementInView);
  //   setScrollY(window.scrollY);
  // }, []);

  // const handleResize = useCallback(() => {
  //   setInitialTop(null);
  // }, []);

  // useEffect(() => {
  //   if (!isChromium) {
  //     window.addEventListener('scroll', handleScroll);
  //     window.addEventListener('resize', handleResize);
  //   }

  //   return () => {
  //     if (!isChromium) {
  //       window.removeEventListener('scroll', handleScroll);
  //       window.removeEventListener('resize', handleResize);
  //     }
  //   };
  // }, [handleScroll]);

  const getScaledValue = (
    start: number,
    end: number,
    triggerBreakpoint: null | number = null,
    shouldReverse = true,
    reversedStart?: number,
    reverseOffset = 0,
  ) => {
    if (isPotato) {
      return end;
    }

    const canReverseWithOffset = actualScrollY - reverseOffset > initialTop;
    const isReversing = canReverseWithOffset && shouldReverse;
    const reversedStartValue = reversedStart ?? start;

    if (triggerBreakpoint !== null && !isReversing) {
      if (percentElementInView >= triggerBreakpoint) {
        return end;
      }
    }

    if (isReversing) {
      const percentScrolledPastElementBottom = Math.min(
        1,
        (actualScrollY - initialTop) / (ref.current?.offsetHeight * 1.5),
      );
      if (start > end) {
        return Math.min(
          start,
          end + (start - end) * percentScrolledPastElementBottom,
        );
      }
      return Math.max(
        reversedStartValue,
        end - (end - reversedStartValue) * percentScrolledPastElementBottom,
      );
    }

    if (start > end) {
      return Math.min(
        start,
        start -
          (start - end) *
            (Math.max(0, percentElementInView - minVisibility) /
              (100 - minVisibility)),
      );
    }

    return Math.min(
      end,
      start +
        (end - start) *
          (Math.max(0, percentElementInView - minVisibility) /
            (100 - minVisibility)),
    );
  };

  return {
    ref,
    percentElementInView,
    getScaledValue,
    canReverse,
    pixelsScrolledPastInitialTop,
  };
};

export default useVisibilityScale;
