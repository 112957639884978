import styled from 'threads5/styles/styled';

// hooks
import { useInEu } from 'src/hooks';

// helpers
import { mutateHardCodedLinks } from 'src/lib/utils';

// constants
import { Locales } from 'src/lib/constants';

// components
import { Link, LocaleSwitch } from 'src/components/Inputs';
import { Lazy } from 'src/components-v2/Helpers';
import TwitterWhiteIcon from 'src/components/SVGs/Icons/TwitterWhiteIcon';
import InstagramIcon from 'src/components/SVGs/Icons/InstagramIcon';

// contexts
import { usePageLocale } from 'src/contexts';

import * as enUsFooter from 'src/store/navigation/footer';
import * as enEuFooter from 'src/store/en-eu/navigation/footer';
import * as enGbFooter from 'src/store/en-gb/navigation/footer';
import {
  Container,
  Row,
  Column,
  Box,
  Stack,
  Spacer,
} from 'src/components-v2/Layout';
import OneTrustButton from './OneTrustButton';

type ListItemProps = {
  name?: string;
  url?: string;
  locale?: string;
  trackingId?: string;
};
const ListItem = ({
  name,
  url,
  locale = '',
  trackingId = '',
}: ListItemProps) => {
  const pageLocale = usePageLocale();
  return (
    <Box
      component='li'
      sx={{
        padding: 0,
        listStyle: 'none',
        a: { color: '#fff', '&:hover': { color: '#63daff' } },
      }}
    >
      {url ? (
        <Link href={url} locale={locale || pageLocale} trackingId={trackingId}>
          <a>{name}</a>
        </Link>
      ) : (
        <>{name}</>
      )}
    </Box>
  );
};

type FooterSectionProps = {
  header: string;
  items: ListItemProps[];
};

const FooterSection = ({ header, items }: FooterSectionProps) => {
  return (
    <nav>
      <Box
        sx={{
          fontWeight: '700',
          color: '#D8FEF3',
        }}
      >
        {header}
      </Box>
      <Box component='ul' sx={{ margin: 0 }}>
        {items.map((item, i) => {
          return <ListItem {...item} key={`${header}-${i}`} />;
        })}
      </Box>
    </nav>
  );
};

const footerPerLocale = {
  [Locales.EN_US]: enUsFooter,
  [Locales.EN_EU]: enEuFooter,
  [Locales.EN_GB]: enGbFooter,
};

const FooterContainer = styled('footer')({});

type FooterProps = {
  roundedTop?: boolean;
  overlap?: boolean;
};

const Footer = ({ roundedTop, overlap }: FooterProps) => {
  const isInEu = useInEu();
  const locale = usePageLocale();

  // data
  const { footerData } = mutateHardCodedLinks({
    data: footerPerLocale[locale] || footerPerLocale[Locales.EN_US],
    locale,
  });
  const { copyrightName, eeaDisclaimer, sections } = footerData;

  return (
    <FooterContainer
      id='footer'
      sx={(theme) => {
        return {
          color: '#fff',
          backgroundColor: '#02294B',
          borderRadius: roundedTop ? '40px 40px 0 0' : '0',
          padding: '40px',
          ...(overlap && {
            marginTop: '-40px',
            position: 'relative',
            zIndex: 10,
          }),
          [theme.breakpoints.up('sm')]: {
            padding: '80px',
          },
          [theme.breakpoints.up('md')]: {
            padding: '80px 100px',
          },
        };
      }}
    >
      <Container sx={{ px: 0 }}>
        <Row>
          <Column mdOffset={1} md={22}>
            <Box
              sx={(theme) => {
                return {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  [theme.breakpoints.up('sm')]: {
                    gridTemplateColumns: 'repeat(4, 1fr)',
                  },
                  [theme.breakpoints.up('lg')]: {
                    gridTemplateColumns: 'repeat(5, 1fr)',
                  },
                };
              }}
              gap={3}
            >
              <Box
                sx={(theme) => {
                  return {
                    gridRow: '1',
                    gridColumn: '1 / span 2',
                    [theme.breakpoints.up('sm')]: {
                      gridRow: '1 / span 3',
                      gridColumn: '1',
                    },
                  };
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='68'
                  height='68'
                  viewBox='0 0 68 68'
                  fill='none'
                >
                  <g filter='url(#filter0_dd_5537_1683)'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M29.0329 7.60001L14.1767 11.452L10.0837 26.2432L15.204 31.4484L10 36.5677L13.8529 51.4243L28.6439 55.5163L33.8478 50.3959L38.9681 55.6L53.8233 51.748L57.9163 36.9547L52.797 31.7517L58 26.6323L54.1481 11.7757L39.3551 7.68375L34.1532 12.8031L29.0329 7.60001ZM19.9141 14.3002L27.7394 12.27L31.1614 15.7483L26.1709 20.6583L19.9141 14.3002ZM37.0996 15.7919L40.5768 12.3708L48.3684 14.5266L42.0095 20.7825L37.0996 15.7919ZM14.7695 25.0325L16.9253 17.2417L23.18 23.5998L18.1905 28.5098L14.7695 25.0325ZM44.9395 23.7682L51.2984 17.5112L53.3265 25.3378L49.8504 28.7589L44.9395 23.7682ZM29.1133 23.6429L34.1038 18.7329L39.0127 23.7236L34.0232 28.6336L29.1133 23.6429ZM21.1387 31.4915L26.1282 26.5815L31.0391 31.5722L26.0486 36.4822L21.1387 31.4915ZM36.9648 31.6292L41.9544 26.7192L46.8643 31.7099L41.8737 36.6199L36.9648 31.6292ZM14.6699 37.861L18.1481 34.439L23.057 39.4307L16.7 45.6856L14.6699 37.861ZM28.9902 39.4738L33.9808 34.5638L38.8907 39.5544L33.9011 44.4644L28.9902 39.4738ZM44.8164 39.6038L49.807 34.6939L53.229 38.1711L51.0732 45.9619L44.8164 39.6038ZM19.6387 48.6764L25.9966 42.4194L30.9075 47.4101L27.4303 50.8322L19.6387 48.6764ZM36.8359 47.4601L41.8255 42.5501L48.0813 48.9082L40.2569 50.9373L36.8359 47.4601Z'
                      fill='white'
                    />
                  </g>
                  <defs>
                    <filter
                      id='filter0_dd_5537_1683'
                      x='0.4'
                      y='0.400006'
                      width='67.2'
                      height='67.2'
                      filterUnits='userSpaceOnUse'
                      colorInterpolationFilters='sRGB'
                    >
                      <feFlood floodOpacity='0' result='BackgroundImageFix' />
                      <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                      />
                      <feOffset dy='2.4' />
                      <feGaussianBlur stdDeviation='4.8' />
                      <feComposite in2='hardAlpha' operator='out' />
                      <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.0666667 0 0 0 0 0.0666667 0 0 0 0 0.0705882 0 0 0 0.08 0'
                      />
                      <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_5537_1683'
                      />
                      <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation='1.2' />
                      <feComposite in2='hardAlpha' operator='out' />
                      <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.0666667 0 0 0 0 0.0666667 0 0 0 0 0.0705882 0 0 0 0.02 0'
                      />
                      <feBlend
                        mode='normal'
                        in2='effect1_dropShadow_5537_1683'
                        result='effect2_dropShadow_5537_1683'
                      />
                      <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect2_dropShadow_5537_1683'
                        result='shape'
                      />
                    </filter>
                  </defs>
                </svg>
              </Box>
              <Box>
                {sections.products != null && (
                  <Stack>
                    <FooterSection {...sections.products} />
                  </Stack>
                )}
              </Box>
              <Box>
                {sections.aboutUs != null && (
                  <FooterSection {...sections.aboutUs} />
                )}
              </Box>
              <Box>
                {sections.useCases != null && (
                  <FooterSection {...sections.useCases} />
                )}
              </Box>
              <Box>
                {sections.forConsumers != null && (
                  <FooterSection {...sections.forConsumers} />
                )}
              </Box>
              <Box>
                {sections.resources != null && (
                  <FooterSection {...sections.resources} />
                )}
              </Box>
              <Box>
                {sections.docs != null && <FooterSection {...sections.docs} />}
              </Box>
              <Box>
                {sections.forInstitutions != null && (
                  <FooterSection {...sections.forInstitutions} />
                )}
              </Box>
            </Box>
            <Spacer height={96} />
            <Stack gap={3}>
              <Stack
                direction='row'
                gap={2}
                sx={{
                  a: { width: '40px' },
                }}
              >
                <Link href='https://twitter.com/plaid'>
                  <a aria-label='Go to https://twitter.com/plaid'>
                    <Lazy>
                      <span aria-hidden='true'>
                        <TwitterWhiteIcon />
                      </span>
                    </Lazy>
                  </a>
                </Link>
                <Link href='https://www.instagram.com/plaid/'>
                  <a aria-label='Go to https://www.instagram.com/plaid/'>
                    <Lazy>
                      <span aria-hidden='true'>
                        <InstagramIcon />
                      </span>
                    </Lazy>
                  </a>
                </Link>
              </Stack>
              <Stack
                direction={{ xm: 'column', sm: 'row' }}
                gap={3}
                sx={{ alignItems: 'center' }}
              >
                <Box>
                  &copy; {new Date().getFullYear()} {copyrightName}
                </Box>
                <Box>
                  <LocaleSwitch />
                </Box>
                <Box>
                  <OneTrustButton />
                </Box>
              </Stack>
              {isInEu && (
                <Box
                  sx={{
                    borderTop: '1px solid #0BA299',
                    paddingTop: '30px',
                  }}
                >
                  {eeaDisclaimer}
                </Box>
              )}
            </Stack>
          </Column>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
